import TodoList from 'components/todoList';

const Tasks = () => {
    return (
        <div className="async-jobs management-layout-container">
            <TodoList hideTopBar={false} mainPage={true} height="calc( 100vh - 170px )" />
        </div>
    );
};

export default Tasks;
