import './style.scss';
import { ReactComponent as EmptyTasksIcon } from 'assets/images/emptyTasks.svg';
import { ReactComponent as AddConnectorPlaceholderIcon } from 'assets/images/documents.svg';
import { useContext, useState } from 'react';
import { Context } from 'hooks/store';
import AddConnectorPlaceholder from 'components/addConnectorPlaceholder';
import ConnectorModal from 'components/Modals/ConnectorModal';

const EmptyTasksPlaceholder = (status) => {
    const [state] = useContext(Context);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isConnectionsExists = state?.connections?.length > 0;

    let message;
    if (status?.status === 'empty_search') {
        message = 'No tasks were found';
    } else if (status?.status === 'completed') {
        message = 'There are no completed tasks at the moment';
    } else if (status?.status === 'in_progress') {
        message = 'There are no active tasks at the moment';
    } else {
        message = isConnectionsExists ? 'Analyzing the clusters...' : 'Add new connection';
    }

    return (
        <div className="empty-tasks-placeholder">
            {status?.status === 'available' && !isConnectionsExists ? (
                <AddConnectorPlaceholder
                    img={<AddConnectorPlaceholderIcon />}
                    text="Get Started with Superstream"
                    btnText="Connect a new cluster"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                />
            ) : (
                <>
                    <div className="img">
                        <EmptyTasksIcon />
                    </div>
                    <h3 className={status.status === 'available' ? 'loading' : ''}>{message}</h3>
                </>
            )}
            <ConnectorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </div>
    );
};

export default EmptyTasksPlaceholder;
