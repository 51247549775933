import ConfirmationModal from '../todoList/components/confirmationModal';
import RecreateTopicsModal from '../todoList/components/recreateTopicsModal';
import RecreateRetentionModal from '../todoList/components/recreateRetentionModal';
import React, { useState } from 'react';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import NewJobModal from 'pages/asyncJobs/components/modals/newJobModal';

const FixNow = ({ item, type = 'task', setPending, onComplete, slot = ({}) => {}, queryParams = {} }) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [recreateTopicRetentionModalOpen, setRecreateTopicRetentionModalOpen] = useState(false);
    const [action, setAction] = useState('');
    const [recreateTopicsModalOpen, setRecreateTopicsModalOpen] = useState(false);
    const [openNewJobModal, setOpenNewJobModal] = useState(false);
    const httpRequest = useHttpRequest();

    const markTask = async (details, action) => {
        try {
            let endpoint;
            switch (action) {
                case 'markAsIgnored':
                    endpoint = apiEndpoints.TASKS_IGNORE;
                    break;
                case 'fix':
                    endpoint = apiEndpoints.TASKS_FIX;
                    break;
                case 'markAsCompleted':
                    endpoint = apiEndpoints.TASKS_MARK_AS_COMPLETE;
                    break;
                default:
                    throw new Error('Invalid task type');
            }
            await httpRequest('POST', endpoint, details);
            return { success: true };
        } catch (error) {
            console.error(`Failed to mark task(s) as ${action}:`, error);
            return { success: false, error };
        }
    };

    const handleConfirmationModalSubmit = async (reason) => {
        setPending(true);

        let params;
        switch (type) {
            case 'subTask':
                params = {
                    connection_id: item.connection_id,
                    type: item.type,
                    task_id: item.id
                };
                break;
            case 'task':
                params = {
                    connection_id: item.connection_id,
                    type: item.task_type,
                    reason: reason
                };
                break;
            case 'job':
                params = {
                    job_id: item.id,
                    ...queryParams
                };
                break;
            default:
                throw new Error('Invalid task type');
        }

        let result;
        if (type !== 'job') {
            result = await markTask(params, action).finally(() => setPending(false));
        } else {
            result = await httpRequest('POST', apiEndpoints.TASKS_FIX_BY_SSM, { ...params }).finally(() => setPending(false));
            result = { success: true };
        }

        if (result.success) {
            onComplete(item);
            if (type !== 'job') {
                setOpenNewJobModal(true);
            }
        }
    };

    const handleConfirmationModalOpen = (action = 'fix') => {
        setAction(action);
        setConfirmModalOpen(true);
    };
    const handleConfirmationModalClose = () => {
        setConfirmModalOpen(false);
    };

    const handleRecreateTopicRetentionModalClose = () => {
        setRecreateTopicRetentionModalOpen(false);
    };

    const handleRecreateTopicRetentionModalOpen = () => {
        setRecreateTopicRetentionModalOpen(true);
    };

    const handleRecreateTopics = async (inputValue) => {
        setPending(true);

        let params;
        switch (type) {
            case 'subTask':
                params = {
                    connection_id: item.connection_id,
                    type: item.type,
                    task_id: item.id,
                    action_type: 'recreate_inactive_topic',
                    desired_partitions: parseInt(inputValue)
                };
                break;
            case 'task':
                params = {
                    connection_id: item.connection_id,
                    type: item.task_type,
                    action_type: 'recreate_inactive_topic',
                    desired_partitions: parseInt(inputValue)
                };
                break;
            case 'job':
                params = {
                    job_id: item.id,
                    ...queryParams,
                    action_type: 'recreate_inactive_topic',
                    desired_partitions: parseInt(inputValue)
                };
                break;
            default:
                throw new Error('Invalid task type');
        }

        const result =
            type !== 'job'
                ? await markTask(params, 'fix').finally(() => setPending(false))
                : await httpRequest('POST', apiEndpoints.TASKS_FIX_BY_SSM, { ...params }).finally(() => setPending(false));

        if (result.success) onComplete(item);
    };

    const handleRecreateTopicsModalOpen = () => {
        setRecreateTopicsModalOpen(true);
    };
    const handleRecreateTopicsModalClose = () => {
        setRecreateTopicsModalOpen(false);
    };

    const handleCreateRetentionTopic = async (e) => {
        setPending(true);
        let params;
        switch (type) {
            case 'subTask':
                params = {
                    connection_id: item.connection_id,
                    type: item.type,
                    task_id: item.id,
                    action_type: 'change_topic_retention',
                    desired_retention_bytes: e?.bytes || -1,
                    desired_retention_ms: e?.time_ms || -1
                };
                break;
            case 'task':
                params = {
                    connection_id: item.connection_id,
                    type: item.task_type,
                    action_type: 'change_topic_retention',
                    desired_retention_bytes: e?.bytes || -1,
                    desired_retention_ms: e?.time_ms || -1
                };
                break;
            case 'job':
                params = {
                    job_id: item.id,
                    ...queryParams,
                    action_type: 'change_topic_retention',
                    desired_retention_bytes: e?.bytes || -1,
                    desired_retention_ms: e?.time_ms || -1
                };
                break;
            default:
                throw new Error('Invalid task type');
        }

        const result =
            type !== 'job'
                ? await markTask(params, 'fix').finally(() => setPending(false))
                : await httpRequest('POST', apiEndpoints.TASKS_FIX_BY_SSM, { ...params }).finally(() => setPending(false));

        if (result.success) onComplete(item);
    };

    return (
        <>
            {slot({ handleRecreateTopicsModalOpen, handleConfirmationModalOpen, handleRecreateTopicRetentionModalOpen })}

            <ConfirmationModal openModal={confirmModalOpen} type={action} onSubmit={handleConfirmationModalSubmit} closeModal={handleConfirmationModalClose} />
            <RecreateRetentionModal
                openModal={recreateTopicRetentionModalOpen}
                type={action}
                onSubmit={handleCreateRetentionTopic}
                closeModal={handleRecreateTopicRetentionModalClose}
                connection={item?.connection_id}
            />

            <RecreateTopicsModal
                openModal={recreateTopicsModalOpen}
                onSubmit={handleRecreateTopics}
                closeModal={handleRecreateTopicsModalClose}
                maxAllowedInputValue={item?.metadata?.partitions?.value}
            />

            <NewJobModal openNewJobModal={openNewJobModal} setOpenNewJobModal={setOpenNewJobModal} pendingTask={item} />
        </>
    );
};

export default FixNow;
