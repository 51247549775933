import './style.scss';
import React from 'react';
import { Modal as AntModal } from 'antd';

const Modal = ({ isModalOpen, clickOutside, children, forceRender, width, className, closeIcon }) => {
    return (
        <AntModal
            className={className}
            width={width}
            forceRender={forceRender}
            open={isModalOpen}
            footer={null}
            centered
            destroyOnClose={true}
            onCancel={() => clickOutside()}
            zIndex={9997}
            closeIcon={closeIcon}
        >
            {children}
        </AntModal>
    );
};

export default Modal;
