import "./style.scss";

const Tabs = ({ items, activeItem, handleClickItem }) => {
    return (
        <div className="sm-tab-wrapper">
            {items.map((item, index) => {

                return (
                    <div
                        key={index}
                        className={`sm-tab ${activeItem === item.title ? "active" : ""} ${item.count === 0 ? "disabled" : ""}` }
                        onClick={() => item.count !== 0 ? handleClickItem(item) : false}>
                        <div className="sm-tab-title">
                            {item.title}
                        </div>
                        <div className="sm-tab-count">
                            {item.count}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Tabs;