import "./style.scss";
import { FiSearch } from "react-icons/fi";
import Input from "components/input";


const SearchComponentStateless = ({ onChange, setSearchValue, value, placeholder="Search", style }) => {
    return (
        <div className="search-component" style={style}>
            <div className="search-component-icon">
                <FiSearch color="#737373" />
            </div>
            <div className="search-component-input">
                <Input
                    value={value}
                    onChange={(e) => {onChange(e.target.value); setSearchValue && setSearchValue(e.target.value)}}
                    variant="borderless"
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}

export default SearchComponentStateless;