import './style.scss';
import TooltipComponent from 'components/tooltip/tooltip';
import { FiInfo } from "react-icons/fi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const StatusBadge = ({type, reason, preflightStatus = false, isPreflightStatusBadge = false}) => {
    const mapping = {
        canceled: {label: 'Cancelled', color: '#475467'},
        completed: {label: isPreflightStatusBadge ? 'Passed' : 'Completed', icon: <IoCheckmarkCircleOutline color={"#12B76A"} />},
        fixed: {label: isPreflightStatusBadge ? 'Passed' : 'Completed', icon: <IoCheckmarkCircleOutline color={"#12B76A"} />},
        running: {label: 'Running', color: '#FF9F38'},
        queued: {label: 'Queued', color: '#475467'},
        failed: {label: 'Failed', color: '#FF3B30'}
    };

    if (isPreflightStatusBadge && type === 'canceled') return;

    if (isPreflightStatusBadge && ['failed', 'completed'].includes(type)){
        type = preflightStatus ? 'completed' : 'failed';
    }

    return (
        <div className={"status-badge-wrapper"}>
            {!isPreflightStatusBadge && reason && type === 'failed' ? (
                <TooltipComponent text={reason}>
                    <div className={'status-badge ' + type}>
                        <div style={{position: "relative", top: 1}}>
                            <FiInfo width={20} height={20} color={"#FF3B30"} />
                        </div>
                        <div className="status-badge-icon">{mapping[type]?.icon}</div>
                        <div className="status-badge-text" style={{color: '#475467'}}>{mapping[type]?.label}</div>
                    </div>
                </TooltipComponent>
            ) : (
                <div className={'status-badge ' + type}>
                    {mapping[type]?.icon &&
                        <div className="status-badge-icon">{mapping[type]?.icon}</div>
                    }
                    <div className="status-badge-text" style={{color: mapping[type]?.icon ? '#475467' : mapping[type]?.color}}>{mapping[type]?.label}</div>
                </div>
            )}
        </div>
    );
};

export default StatusBadge;
