import './style.scss';
import { ReactComponent as DashboardIcon } from 'assets/images/jobs/dashboard.svg';
import Button from 'components/button';
import { useNavigate } from 'react-router-dom';

const EmptyPlaceholder = () => {
    const navigate = useNavigate();

    return (
        <div className="jobs-empty-placeholder-wrapper">
            <div className="jobs-empty-placeholder">
                <div className="jobs-empty-placeholder-inner">
                    <div className="jobs-empty-placeholder-icon">
                        <DashboardIcon />
                    </div>
                    <div className="heading">
                        <h2>No jobs yet</h2>
                    </div>
                    <Button customClassName={'btn-primary'} placeholder={'View all available tasks'} onClick={() => navigate('/todo')} />
                </div>
            </div>
        </div>
    );
};

export default EmptyPlaceholder;
