import './style.scss';
import { Spin, Badge } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { HiOutlineRefresh } from 'react-icons/hi';

const RefreshButton = ({ refreshIndeicator, onClick, isLoading, style, color="var(--refresh-btn-color)" }) => {
    return (
        <span className="update-refresh">
            {refreshIndeicator && <Badge dot />}
            <div className="refresh-btn" onClick={onClick} style={style}>
                {isLoading ? (
                    <Spin className="refresh-icon-spin" indicator={<SyncOutlined spin />} />
                ) : (
                    <HiOutlineRefresh alt="refreshIcon" color={color} />
                )}
            </div>
        </span>
    );
};

export default RefreshButton;
