import './filterStyle.scss';
import { useContext, useState } from 'react';
import Button from 'components/button';
import { Badge, Checkbox, Collapse } from 'antd';
import { IoIosArrowDown } from 'react-icons/io';
import Popover from 'components/popover';
import { TbFilterSearch } from 'react-icons/tb';
import { Context, FilterContext } from 'hooks/store';

const overlayStyles = {
    borderRadius: '12px',
    width: '279px',
    marginTop: '8px',
    padding: '0px'
};

const FilterButton = ({ disabled, disableApplyButton = false, buttonStyle, hideConnectionNames = false }) => {
    const [popoverShown, setPopoverShown] = useState(false);
    const [state, dispatch] = useContext(FilterContext);
    const [globalState] = useContext(Context);

    const panelStyle = {
        marginBottom: 24,
        background: 'transparent',
        border: 'none'
    };

    const handleSelectedFilters = (field, value) => {
        const currentFilters = state?.selectedFilters[field] || [];

        if (currentFilters.includes(value)) {
            const newFilters = currentFilters.filter((item) => item !== value);
            dispatch({
                type: 'SET_SELECTED_FILTERS',
                payload: { ...state?.selectedFilters, [field]: newFilters }
            });
        } else {
            const newFilters = [...currentFilters, value];
            dispatch({
                type: 'SET_SELECTED_FILTERS',
                payload: { ...state?.selectedFilters, [field]: newFilters }
            });
        }
    };

    const groupTypesOption = {
        remove_inactive_cg: 'Remove inactive cg',
        remove_inactive_topic: 'Remove inactive topics',
        reduce_payload: 'Reduce payload',
        compress_payload: 'Compress payload'
    };

    const tagOptions = ['Storage', 'Transfer', 'Performance', 'Compute'];

    const totalCount = Object.values(state?.selectedFilters).reduce((acc, curr) => acc + curr.length, 0);

    const filterItems = [
        {
            key: '1',
            label: 'Groups type',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {Object.entries(groupTypesOption).map(([key, value]) => {
                        return (
                            <div key={key} className="clients-filter-popover-checkbox" style={{ display: 'flex', gap: 12 }}>
                                <Checkbox
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => handleSelectedFilters('groupTypes', key)}
                                    checked={state?.selectedFilters['groupTypes']?.includes(key)}
                                />
                                {value}
                            </div>
                        );
                    })}
                </div>
            ),
            style: panelStyle,
            disabled: false
        },
        {
            key: '2',
            label: 'Tags',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {tagOptions.map((item, index) => {
                        return (
                            <div key={index} className="clients-filter-popover-checkbox" style={{ display: 'flex', gap: 12 }}>
                                <Checkbox
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => handleSelectedFilters('tagOptions', item)}
                                    checked={state?.selectedFilters['tagOptions']?.includes(item)}
                                />
                                {item}
                            </div>
                        );
                    })}
                </div>
            ),
            style: panelStyle,
            disabled: false
        },
        {
            key: '3',
            label: 'Fixable',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <div key={1} className="clients-filter-popover-checkbox" style={{ display: 'flex', gap: 12 }}>
                        <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => handleSelectedFilters('fixable', true)}
                            checked={state?.selectedFilters['fixable']?.includes(true)}
                        />
                        True
                    </div>
                    <div key={2} className="clients-filter-popover-checkbox" style={{ display: 'flex', gap: 12 }}>
                        <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => handleSelectedFilters('fixable', false)}
                            checked={state?.selectedFilters['fixable']?.includes(false)}
                        />
                        False
                    </div>
                </div>
            ),
            style: panelStyle
        },
        {
            key: '4',
            label: 'Cluster name',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {globalState?.connections?.map((item, index) => {
                        return (
                            <div key={index} className="clients-filter-popover-checkbox" style={{ display: 'flex', gap: 12 }}>
                                <Checkbox
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => handleSelectedFilters('connection_names', item?.name)}
                                    checked={state?.selectedFilters['connection_names']?.includes(item?.name)}
                                />
                                {item?.name}
                            </div>
                        );
                    })}
                </div>
            ),
            style: panelStyle,
            disabled: globalState?.connections?.length < 2 || hideConnectionNames
        }
    ];

    const contentPopover = (
        <div className="clients-filter-popover">
            <div className="top">
                <p>Filter</p>
                <Button
                    onClick={() => {
                        dispatch({ type: 'SET_RESET_FILTERS', payload: true });
                        setPopoverShown(false);
                    }}
                    disabled={totalCount === 0}
                    typeOfButton={'text'}
                    placeholder={<span style={{ color: 'var(--primary-color)' }}>Clear All</span>}
                />
            </div>
            <div className="body">
                <Collapse
                    defaultActiveKey={['1', '2', '3', '4']}
                    expandIconPosition="end"
                    bordered={false}
                    style={{
                        background: 'transparent'
                    }}
                    items={filterItems.filter((item) => !item.disabled)}
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <IoIosArrowDown color="var(--text-color)" style={{ rotate: '180deg', fontSize: 16 }} />
                        ) : (
                            <IoIosArrowDown color="var(--text-color)" style={{ rotate: '0deg', fontSize: 16 }} />
                        )
                    }
                />
            </div>
            <div className="bottom">
                <Button
                    customClassName="modal-btn modal-btn-secondary"
                    placeholder={'Cancel'}
                    onClick={() => setPopoverShown(false)}
                    style={{ padding: '5px 10px', fontSize: 13 }}
                />
                <Button
                    customClassName="modal-btn modal-btn-primary"
                    placeholder={'Apply'}
                    onClick={() => {
                        dispatch({ type: 'SET_APPLY_FILTERS', payload: true });
                        setPopoverShown(false);
                    }}
                    disabled={disableApplyButton}
                    style={{ padding: '5px 10px', fontSize: 13 }}
                />
            </div>
        </div>
    );

    return (
        <div className="clients-filter">
            <Popover
                overlayInnerStyle={overlayStyles}
                placement="bottom"
                content={contentPopover}
                trigger="click"
                onOpenChange={() => setPopoverShown(!popoverShown)}
                open={!disabled ? popoverShown : false}
            >
                <>
                    <Badge count={totalCount?.toString()} color="var(--primary-color)">
                        <Button
                            shape={'circle'}
                            size="large"
                            icon={<TbFilterSearch color="rgb(115, 115, 115)" />}
                            style={{ background: 'var(--bg-white)', ...buttonStyle }}
                            disabled={disabled}
                        />
                    </Badge>
                </>
            </Popover>
        </div>
    );
};

export default FilterButton;
