import React from 'react';
import './style.scss';

const SortIcons = ({ sortOrder }) => {
    const getColor = (order, direction) => {
        if (!order) return '#667085';
        return order === direction ? '#6358FA' : '#667085';
    };

    return (
        <div className="sort-icons">
            <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.75719 14.3752L0.992188 10.6177"
                      stroke={getColor(sortOrder, "ascend")}
                      strokeWidth="1.3"
                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.75781 1.625V14.375"
                      stroke={getColor(sortOrder, "ascend")}
                      strokeWidth="1.3" strokeMiterlimit="10"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.24219 1.625L5.00719 5.3825"
                      stroke={getColor(sortOrder, "descend")}
                      strokeWidth="1.3"
                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.24219 14.375V1.625"
                      stroke={getColor(sortOrder, "descend")}
                      strokeWidth="1.3" strokeMiterlimit="10"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};

export default SortIcons;
