import React, { useEffect, useState, useContext } from 'react';
import { Form, Select } from 'antd';
import Input from 'components/input';
import Button from 'components/button';
import KeyModalForm from 'components/Modals/KeyModal/keyModalComponents/form';
import { Context } from 'hooks/store';
import { IoIosArrowDown } from 'react-icons/io';
import { LuPlus } from 'react-icons/lu';
import { FaArrowLeft } from 'react-icons/fa';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';

const ConnectorModalForm = ({ onFinish, form, updated, updateMode }) => {
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [formFields, setFormFields] = useState([]);
    const [formSettingsState, updateFormSettingsState] = useState('');
    const [DPOptoins, setDPOptoins] = useState([]);
    const [keysForm] = Form.useForm();
    const [createKeyModal, setCreateKeyModal] = useState(false);
    const [keyLoading, setKeyLoading] = useState(false);
    const [errMessage, setErrMessage] = useState(null);
    const [insecure_skiped, setInsecureSkiped] = useState(false);

    useEffect(() => {
        setFormFields(form.getFieldsValue());
    }, [form]);

    useEffect(() => {
        getAllKeys();
        setDPOptoins(state?.environments?.map((option) => ({ value: option, label: option })) || []);
    }, []);

    useEffect(() => {
        updated(formFields);
        if (form.getFieldsValue()['insecure_skip_verify'] === 'true') {
            setInsecureSkiped(true);
        } else {
            setInsecureSkiped(false);
        }
    }, [formFields]);

    const createKey = async (values, inputName, keysForm) => {
        setKeyLoading(true);
        try {
            setErrMessage(null);
            const data = await httpRequest('POST', apiEndpoints.CREATE_KEY, values);
            await getAllKeys();
            form.setFieldValue(inputName, data?.key?.id);
            setFormFields({ ...formFields, [inputName]: data?.key?.id });
            setKeyLoading(false);
            setCreateKeyModal(false);
            setErrMessage(null);
            keysForm.resetFields();
        } catch (error) {
            if (error.status === 499) {
                setErrMessage(error?.data?.error);
            }
            setKeyLoading(false);
        }
    };

    const getAllKeys = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ALL_KEYS);
            dispatch({ type: 'SET_KEYS_LIST', payload: data?.keys });
        } catch (error) {}
    };

    const connectorFormFields = [
        {
            name: 'name',
            type: 'string',
            label: 'Connection name',
            required: true,
            placeholder: 'Enter connection name'
        },
        {
            name: 'data_plane',
            type: 'select',
            label: 'Engine',
            required: true,
            placeholder: 'Choose engine',
            options: DPOptoins,
            disabled: updateMode
        },
        {
            name: 'type',
            type: 'select',
            label: 'Vendor',
            required: true,
            placeholder: 'Choose vendor',
            options: [
                { value: 'msk', label: 'MSK' },
                { value: 'msk_serverless', label: 'MSK (Serverless)' },
                { value: 'confluent_cloud_kafka', label: 'Confluent (Cloud)' },
                { value: 'confluent_kafka', label: 'Confluent (Self-Hosted)' },
                { value: 'apache_kafka', label: 'Apache Kafka' },
                { value: 'redpanda', label: 'Redpanda' },
                { value: 'aiven', label: 'Aiven' }
            ],
            disabled: updateMode,
            dependent: {
                confluent_cloud_kafka: [
                    {
                        name: 'cluster_id',
                        type: 'string',
                        label: 'Cluster ID',
                        required: true,
                        placeholder: 'Enter cluster ID'
                    },
                    {
                        name: 'key',
                        type: 'select',
                        label: 'Kafka vendor API key',
                        description: 'Superstream utilizes the Confluent cloud kafka API to integrate and cross data with billing and monitoring metrics',
                        required: true,
                        placeholder: 'Choose Kafka vendor API key',
                        options: state?.keysList?.filter((key) => key?.vendor === formFields?.type).map((option) => ({ value: option?.id, label: option?.name })) || [],
                        create: true
                    }
                ],
                aiven: [
                    {
                        name: 'cluster_id',
                        type: 'string',
                        label: 'Project name & Service Name',
                        required: true,
                        placeholder: '<your project>/<your service>'
                    },
                    {
                        name: 'key',
                        type: 'select',
                        label: 'Kafka vendor API key',
                        description: 'Superstream utilizes the Aiven kafka API to integrate and cross data with billing and monitoring metrics',
                        required: true,
                        placeholder: 'Choose Kafka vendor API key',
                        options: state?.keysList?.filter((key) => key?.vendor === formFields?.type).map((option) => ({ value: option?.id, label: option?.name })) || [],
                        create: true
                    }
                ],
                msk: [
                    {
                        name: 'cluster_id',
                        type: 'string',
                        label: 'Cluster ARN',
                        required: true,
                        placeholder: 'Enter cluster ARN'
                    },
                    {
                        name: 'region',
                        type: 'select',
                        label: 'Region',
                        required: true,
                        placeholder: 'Enter region',
                        options: [
                            { value: 'af-south-1', label: 'af-south-1' },
                            { value: 'ap-east-1', label: 'ap-east-1' },
                            { value: 'ap-northeast-1', label: 'ap-northeast-1' },
                            { value: 'ap-northeast-2', label: 'ap-northeast-2' },
                            { value: 'ap-northeast-3', label: 'ap-northeast-3' },
                            { value: 'ap-south-1', label: 'ap-south-1' },
                            { value: 'ap-south-2', label: 'ap-south-2' },
                            { value: 'ap-southeast-1', label: 'ap-southeast-1' },
                            { value: 'ap-southeast-2', label: 'ap-southeast-2' },
                            { value: 'ap-southeast-3', label: 'ap-southeast-3' },
                            { value: 'ap-southeast-4', label: 'ap-southeast-4' },
                            { value: 'ca-central-1', label: 'ca-central-1' },
                            { value: 'cn-north-1', label: 'cn-north-1' },
                            { value: 'cn-northwest-1', label: 'cn-northwest-1' },
                            { value: 'eu-central-1', label: 'eu-central-1' },
                            { value: 'eu-central-2', label: 'eu-central-2' },
                            { value: 'eu-north-1', label: 'eu-north-1' },
                            { value: 'eu-south-1', label: 'eu-south-1' },
                            { value: 'eu-south-2', label: 'eu-south-2' },
                            { value: 'eu-west-1', label: 'eu-west-1' },
                            { value: 'eu-west-2', label: 'eu-west-2' },
                            { value: 'eu-west-3', label: 'eu-west-3' },
                            { value: 'il-central-1', label: 'il-central-1' },
                            { value: 'me-central-1', label: 'me-central-1' },
                            { value: 'me-south-1', label: 'me-south-1' },
                            { value: 'sa-east-1', label: 'sa-east-1' },
                            { value: 'us-east-1', label: 'us-east-1' },
                            { value: 'us-east-2', label: 'us-east-2' },
                            { value: 'us-gov-east-1', label: 'us-gov-east-1' },
                            { value: 'us-gov-west-1', label: 'us-gov-west-1' },
                            { value: 'us-west-1', label: 'us-west-1' },
                            { value: 'us-west-2', label: 'us-west-2' }
                        ]
                    },
                    {
                        name: 'key',
                        type: 'select',
                        label: 'Kafka vendor API key',
                        description: 'Superstream utilizes the MSK kafka API to integrate and cross data with billing and monitoring metrics',
                        required: true,
                        placeholder: 'Choose Kafka vendor API key',
                        options: state?.keysList?.filter((key) => key?.vendor === formFields?.type).map((option) => ({ value: option?.id, label: option?.name })) || [],
                        create: true
                    }
                ],
                msk_serverless: [
                    {
                        name: 'cluster_id',
                        type: 'string',
                        label: 'Cluster ARN',
                        required: true,
                        placeholder: 'Enter cluster ARN'
                    },
                    {
                        name: 'region',
                        type: 'select',
                        label: 'Region',
                        required: true,
                        placeholder: 'Enter region',
                        options: [
                            { value: 'af-south-1', label: 'af-south-1' },
                            { value: 'ap-east-1', label: 'ap-east-1' },
                            { value: 'ap-northeast-1', label: 'ap-northeast-1' },
                            { value: 'ap-northeast-2', label: 'ap-northeast-2' },
                            { value: 'ap-northeast-3', label: 'ap-northeast-3' },
                            { value: 'ap-south-1', label: 'ap-south-1' },
                            { value: 'ap-south-2', label: 'ap-south-2' },
                            { value: 'ap-southeast-1', label: 'ap-southeast-1' },
                            { value: 'ap-southeast-2', label: 'ap-southeast-2' },
                            { value: 'ap-southeast-3', label: 'ap-southeast-3' },
                            { value: 'ap-southeast-4', label: 'ap-southeast-4' },
                            { value: 'ca-central-1', label: 'ca-central-1' },
                            { value: 'cn-north-1', label: 'cn-north-1' },
                            { value: 'cn-northwest-1', label: 'cn-northwest-1' },
                            { value: 'eu-central-1', label: 'eu-central-1' },
                            { value: 'eu-central-2', label: 'eu-central-2' },
                            { value: 'eu-north-1', label: 'eu-north-1' },
                            { value: 'eu-south-1', label: 'eu-south-1' },
                            { value: 'eu-south-2', label: 'eu-south-2' },
                            { value: 'eu-west-1', label: 'eu-west-1' },
                            { value: 'eu-west-2', label: 'eu-west-2' },
                            { value: 'eu-west-3', label: 'eu-west-3' },
                            { value: 'il-central-1', label: 'il-central-1' },
                            { value: 'me-central-1', label: 'me-central-1' },
                            { value: 'me-south-1', label: 'me-south-1' },
                            { value: 'sa-east-1', label: 'sa-east-1' },
                            { value: 'us-east-1', label: 'us-east-1' },
                            { value: 'us-east-2', label: 'us-east-2' },
                            { value: 'us-gov-east-1', label: 'us-gov-east-1' },
                            { value: 'us-gov-west-1', label: 'us-gov-west-1' },
                            { value: 'us-west-1', label: 'us-west-1' },
                            { value: 'us-west-2', label: 'us-west-2' }
                        ]
                    },
                    {
                        name: 'key',
                        type: 'select',
                        label: 'Kafka vendor API key',
                        description: 'Superstream utilizes the MSK kafka API to integrate and cross data with billing and monitoring metrics',
                        required: true,
                        placeholder: 'Choose Kafka vendor API key',
                        options: state?.keysList?.filter((key) => key?.vendor === formFields?.type).map((option) => ({ value: option?.id, label: option?.name })) || [],
                        create: true
                    }
                ]
            }
        },
        {
            name: 'bootstrap_servers',
            type: 'string',
            label: 'Bootstrap servers',
            required: true,
            placeholder: 'Enter bootstrap servers name',
            disabled: updateMode
        },
        {
            name: 'security_protocol',
            type: 'select',
            label: 'Security protocol',
            description: 'SSL / SASL_SSL / No authentication',
            required: true,
            placeholder: 'Choose security protocol',
            options: [
                { value: 'SSL', label: 'SSL' },
                { value: 'SASL_SSL', label: 'SASL_SSL' },
                { value: 'no_authentication', label: 'No authentication' }
            ],
            disabled: updateMode,
            dependent: {
                SSL: [
                    {
                        name: 'insecure_skip_verify',
                        label: 'Insecure skip verify',
                        placeholder: 'Choose insecure skip verify',
                        type: 'select',
                        options: [
                            {
                                value: 'true',
                                label: 'True'
                            },
                            {
                                value: 'false',
                                label: 'False'
                            }
                        ],
                        required: true,
                        description: 'true / false',
                        disabled: updateMode,
                        dependent: {
                            false: [
                                {
                                    name: 'ssl_key_pem',
                                    label: 'SSL Key Pem',
                                    type: 'string',
                                    required: !insecure_skiped,
                                    placeholder: '-----BEGIN PRIVATE KEY----- \n...\n-----END PRIVATE KEY-----',
                                    disabled: updateMode
                                },
                                {
                                    name: 'ssl_certificate_pem',
                                    label: 'SSL certificate pem',
                                    type: 'string',
                                    required: !insecure_skiped,
                                    placeholder: '-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----',
                                    disabled: updateMode
                                },
                                {
                                    name: 'ssl_ca_pem',
                                    label: 'SSL CA PEM',
                                    type: 'string',
                                    required: !insecure_skiped,
                                    placeholder: '-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----',
                                    disabled: updateMode
                                }
                            ]
                        }
                    }
                ],
                SASL_SSL: [
                    {
                        name: 'sasl_mechanism',
                        label: 'SASL mechanism',
                        type: 'select',
                        options:
                            formFields?.type === 'msk' || formFields?.type === 'msk_serverless'
                                ? [
                                      { value: 'SCRAM-SHA-256', label: 'SCRAM-SHA-256' },
                                      { value: 'SCRAM-SHA-512', label: 'SCRAM-SHA-512' },
                                      { value: 'PLAIN', label: 'PLAIN' },
                                      { value: 'AWS_MSK_IAM', label: 'IAM' }
                                  ]
                                : [
                                      { value: 'SCRAM-SHA-256', label: 'SCRAM-SHA-256' },
                                      { value: 'SCRAM-SHA-512', label: 'SCRAM-SHA-512' },
                                      { value: 'PLAIN', label: 'PLAIN' }
                                  ],
                        placeholder: 'Choose SASL mechanism',
                        required: true,
                        disabled: updateMode
                    },
                    {
                        name: 'sasl_username',
                        label: 'SASL username',
                        type: 'string',
                        required: true,
                        placeholder: 'Username',
                        disabled: updateMode
                    },
                    {
                        name: 'sasl_password',
                        label: 'SASL password',
                        type: 'string',
                        required: true,
                        placeholder: 'Password',
                        disabled: updateMode
                    },
                    {
                        name: 'tls_enabled',
                        label: 'TLS enabled',
                        type: 'select',
                        options: [
                            { value: 'custom', label: 'Custom' },
                            { value: 'default', label: 'Default' },
                            { value: 'none', label: 'None' }
                        ],
                        required: true,
                        placeholder: 'Choose TLS enabled',
                        description: 'custom / default / none (no tls)',

                        disabled: updateMode,
                        dependent: {
                            custom: [
                                {
                                    name: 'ssl_key_pem',
                                    label: 'SSL Key Pem',
                                    type: 'string',
                                    required: !insecure_skiped,
                                    placeholder: '-----BEGIN PRIVATE KEY----- \n...\n-----END PRIVATE KEY-----',
                                    disabled: updateMode
                                },
                                {
                                    name: 'ssl_certificate_pem',
                                    label: 'SSL certificate pem',
                                    type: 'string',
                                    required: !insecure_skiped,
                                    placeholder: '-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----',
                                    disabled: updateMode
                                },
                                {
                                    name: 'ssl_ca_pem',
                                    label: 'SSL CA PEM',
                                    type: 'string',
                                    required: !insecure_skiped,
                                    placeholder: '-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----',
                                    disabled: updateMode
                                },
                                {
                                    name: 'insecure_skip_verify',
                                    label: 'Insecure skip verify',
                                    type: 'select',
                                    placeholder: 'Choose insecure skip verify',
                                    disabled: updateMode,
                                    options: [
                                        {
                                            value: 'true',
                                            label: 'True'
                                        },
                                        {
                                            value: 'false',
                                            label: 'False'
                                        }
                                    ],
                                    required: true,
                                    description: 'true / false'
                                }
                            ],
                            default: [],
                            none: []
                        }
                    }
                ],
                no_authentication: [],
                disabled: updateMode
            }
        }
    ];

    const generateFormItem = (form, input, index, depth, inputName) => {
        return (
            <div key={`${input.name}_${index}`}>
                <Form.Item
                    key={input.name}
                    name={input?.name}
                    validateTrigger="onChange"
                    rules={[
                        {
                            required: input?.required,
                            message: `required`
                        }
                    ]}
                >
                    {input?.type === 'string' && (
                        <div key={'input_' + input.name}>
                            <Input
                                label={
                                    <>
                                        {input.label}
                                        {input.required && <span className="ms-form-required">*</span>}
                                    </>
                                }
                                type="text"
                                placeholder={input.placeholder}
                                value={formFields[input.name]}
                                onChange={(e) => {
                                    form.setFieldValue(input.name, e.target.value);
                                    setFormFields({ ...formFields, [input.name]: e.target.value });
                                }}
                                disabled={input?.disabled}
                            />
                        </div>
                    )}
                    {input?.type === 'select' && (
                        <div key={'select_' + input.name}>
                            <>
                                <div className="ms-form-title-wrapper">
                                    <div className="ms-form-label-wrapper" style={{ marginBottom: '10px' }}>
                                        <span className="title">
                                            <label htmlFor={input.name} className="ms-form-label">
                                                {input.label}
                                                {input.required && <span className="ms-form-required">*</span>}
                                            </label>
                                            {input?.create && state?.keysList?.filter((key) => key?.vendor === formFields?.type)?.length > 0 && (
                                                <span onClick={() => setCreateKeyModal(true)}>
                                                    <LuPlus />
                                                    Create Kafka vendor API key
                                                </span>
                                            )}
                                        </span>
                                        <div className="ms-form-label-two">{input.description}</div>
                                    </div>
                                </div>

                                {(!input?.create || (input?.create && state?.keysList?.filter((key) => key?.vendor === formFields?.type)?.length > 0)) && (
                                    <Select
                                        suffixIcon={<IoIosArrowDown />}
                                        id={input.name}
                                        placeholder={input.placeholder}
                                        options={input.options}
                                        value={input.name === 'key' && formFields[input.name] === 0 ? null : formFields[input.name]}
                                        onChange={(value) => {
                                            form.setFieldValue(input.name, value);
                                            setFormFields({ ...formFields, [input.name]: value });
                                            updateFormSettingsState(value); // Update state with selected value
                                        }}
                                        disabled={input?.disabled}
                                    />
                                )}
                            </>
                            {input?.create &&
                                (createKeyModal || state?.keysList?.filter((key) => key?.vendor === formFields?.type)?.length === 0 || !state?.keysList) && (
                                    <div className="key-modal-form" style={state?.keysList?.length > 0 ? { marginTop: '20px' } : undefined}>
                                        <div className="key-modal-form-header">
                                            {state?.keysList?.filter((key) => key?.vendor === formFields?.type)?.length > 0 && (
                                                <FaArrowLeft alt="Back" onClick={() => setCreateKeyModal(false)} className="back-icon" color="var(--text-color)" />
                                            )}
                                            <p className="create-api">Create Kafka vendor API key</p>
                                        </div>
                                        <KeyModalForm
                                            onFinish={(values) => createKey(values, input.name, keysForm)}
                                            form={keysForm}
                                            connectionView={true}
                                            vendor={formFields?.type || form?.getFieldValue('type')}
                                        />
                                        <Button
                                            customClassName="modal-btn modal-btn-primary create-key-btn"
                                            placeholder="Create a key"
                                            onClick={() => keysForm.submit()}
                                            loading={keyLoading}
                                        />
                                        {errMessage && <label className="error-message">{errMessage}</label>}
                                    </div>
                                )}
                        </div>
                    )}
                </Form.Item>
                {input.dependent &&
                    Object.entries(input.dependent).map(([value, children], index) => {
                        if (form.getFieldValue(input.name) !== value) return <React.Fragment key={`${value}_${index}`} />;
                        return children.map((child, childIndex) => generateFormItem(form, child, childIndex, depth));
                    })}
            </div>
        );
    };

    return (
        <Form name="connector-modal" onFinish={onFinish} autoComplete="off" form={form}>
            {connectorFormFields.map(
                (input, index) => generateFormItem(form, input, 'parent_' + index, 0) // Initial depth: 0
            )}
        </Form>
    );
};

export default ConnectorModalForm;
