import './style.scss';
import Badge from '../badge';
import { Spin } from 'antd';
import Button from '../button';
import { IoIosArrowForward } from 'react-icons/io';
import { ReactComponent as StorageIcon } from 'assets/images/badgeIcons/storage.svg';
import { ReactComponent as CPUIcon } from 'assets/images/badgeIcons/cpu.svg';
import { ReactComponent as MemoryIcon } from 'assets/images/badgeIcons/memory.svg';
import { ReactComponent as PartitionsIcon } from 'assets/images/badgeIcons/partitions.svg';
import { ReactComponent as PerformanceIcon } from 'assets/images/badgeIcons/performance.svg';
import { ReactComponent as RWIcon } from 'assets/images/badgeIcons/rw.svg';
import React, { forwardRef, useState } from 'react';
import FixNow from '../fixNowButton';
import FixNowButton from '../fixNowButton/fixNowButton';

const ListItem = forwardRef(({ openDrawer, handleSelect, item, className, status, handleItemRemove }, ref) => {
    const { title, tags } = item;
    const [loading, setLoading] = useState(false);
    const [loadingItem, setLoadingItem] = useState(false);

    const getBadge = (category, index) => {
        const mapping = {
            Storage: {
                icon: <StorageIcon />,
                color: '#005AEE',
                backgroundColor: 'rgba(0, 90, 238, 0.12)'
            },
            Compute: {
                icon: <CPUIcon />,
                color: '#9D4EDD',
                backgroundColor: 'rgba(157, 78, 221, 0.12)'
            },
            memory: {
                icon: <MemoryIcon />,
                color: '#F97B53',
                backgroundColor: 'rgba(249, 123, 83, 0.12)'
            },
            Partitions: {
                icon: <PartitionsIcon />,
                color: '#FF55B0',
                backgroundColor: 'rgba(255, 85, 176, 0.12)'
            },
            Performance: {
                icon: <PerformanceIcon />,
                color: '#362DA4',
                backgroundColor: 'rgba(54, 45, 164, 0.12)'
            },
            Transfer: {
                icon: <RWIcon />,
                color: '#039855',
                backgroundColor: 'rgba(3, 152, 85, 0.12)'
            }
        };

        return (
            <Badge
                key={'badge-' + index}
                icon={mapping[category]?.icon}
                text={category}
                type={category + ' border-rounded'}
                color={'#005AEE'}
                backgroundColor={'rgba(0, 90, 238, 0.12)'}
            />
        );
    };

    const handleFixCompleted = () => {
        handleItemRemove();
    };

    const handleOpenDrawer = (item) => {
        setLoadingItem(true);
        openDrawer(item).finally(() => setLoadingItem(false));
    };

    return (
        <div className="list-item-root" ref={ref}>
            <Spin spinning={loadingItem} delay={100}>
                <div className={'list-item ' + className}>
                    <div className="list-item-heading" onClick={() => handleOpenDrawer(item)}>
                        <p className="list-item-title">{title}</p>
                        <div className="todo-list-status-wrapper"></div>
                    </div>
                    <div className="list-item-badges" onClick={() => handleOpenDrawer(item)}>
                        <div className="left">{tags?.map((category, index) => getBadge(category, index))}</div>
                        <div className="right">
                            {['remove_inactive_topic', 'remove_inactive_empty_topic'].includes(item?.task_type) && item?.auto_create_topics !== undefined && (
                                <Badge
                                    text={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            {item.auto_create_topics ? 'auto.topic.creation is enabled' : 'auto.topic.creation is disabled'}
                                        </div>
                                    }
                                    type={item?.auto_create_topics ? 'success' : 'warning'}
                                />
                            )}
                        </div>
                    </div>
                    <div className="list-item-actions">
                        {!['in_progress', 'completed'].includes(status) && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                                <FixNow
                                    item={item}
                                    type={'task'}
                                    setPending={setLoading}
                                    onComplete={handleFixCompleted}
                                    slot={({ handleRecreateTopicsModalOpen, handleConfirmationModalOpen, handleRecreateTopicRetentionModalOpen }) => {
                                        return (
                                            <FixNowButton
                                                item={item}
                                                triggerConfirmation={handleConfirmationModalOpen}
                                                triggerRecreateTopicsModal={handleRecreateTopicsModalOpen}
                                                triggerChangeRetentionModal={handleRecreateTopicRetentionModalOpen}
                                            />
                                        );
                                    }}
                                />

                                <Button
                                    typeOfButton={'text'}
                                    placeholder={<IoIosArrowForward fontSize={18} color={'#475467'} />}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => handleOpenDrawer(item)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Spin>
        </div>
    );
});

export default ListItem;
