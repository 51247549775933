import './style.scss';
import InfoCard from './infoCard';
import { ReactComponent as PartitionIcon } from 'assets/images/dashboard/partitions.svg';
import { ReactComponent as StorageIcon } from 'assets/images/dashboard/storage.svg';
import { ReactComponent as WriteIcon } from 'assets/images/dashboard/write.svg';
import { ReactComponent as ReadIcon } from 'assets/images/dashboard/read.svg';
import { convertStorage } from 'services/genericServices';

const InfoCards = ({ data }) => {
    const items = [
        {
            icon: <StorageIcon />,
            count: data?.total_storage_bytes_reduced > 0 ? convertStorage(data?.total_storage_bytes_reduced) : 0,
            label: 'Amount of reduced storage'
        },
        {
            icon: <PartitionIcon />,
            count: data?.total_partitions_reduced,
            label: 'Number of reduced partitions'
        },
        {
            icon: <ReadIcon />,
            count: data?.total_read_bytes_reduced > 0 ? convertStorage(data?.total_read_bytes_reduced) : 0,
            label: 'Read traffic reduction'
        },
        {
            icon: <WriteIcon />,
            count: data?.total_write_bytes_reduced > 0 ? convertStorage(data?.total_write_bytes_reduced) : 0,
            label: 'Write traffic reduction'
        }
    ];

    return (
        <div className="info-card-wrapper">
            {items.map((item, index) => {
                return <InfoCard key={index} icon={item?.icon} count={item?.count} label={item?.label} />;
            })}
        </div>
    );
};

export default InfoCards;
