import Dagre from '@dagrejs/dagre';

const formattedData = (listOfDataPlanes, setDataPlanModal, setIsModalOpen, handleClickOnClientNode, handleOrphanClients) => {
    let nodes = [];
    let edges = [];

    nodes.push({ id: 'superstream', type: 'superstream', data: { label: 'Superstream' } });
    nodes.push({
        id: 'addDataPlan',
        type: 'addDataPlan',
        data: {
            label: '+ New Engine',
            onClick: (e) => {
                e.stopPropagation();
                setDataPlanModal(true);
            }
        }
    });
    edges.push({
        source: 'superstream',
        target: 'addDataPlan',
        style: { stroke: '#5A4FE5', strokeWidth: 2 },
        animated: true
    });

    listOfDataPlanes.forEach((dataPlan) => {
        const connections = dataPlan?.connections || [];

        if (connections.length === 0) {
            nodes.push({
                id: 'addConnector-' + dataPlan.id,
                type: 'addConnector',
                data: {
                    label: 'Add Connector',
                    onClick: (e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                    }
                }
            });
            edges.push({
                source: 'dataPlane-' + dataPlan.id,
                target: 'addConnector-' + dataPlan.id,
                style: { stroke: '#5A4FE5', strokeWidth: 2 },
                animated: false
            });
        }

        nodes.push({
            id: 'dataPlane-' + dataPlan.id,
            type: 'dataPlane',
            target: 'addConnector-0',
            data: {
                label: dataPlan.env_name,
                connections: connections.length
            }
        });
        edges.push({
            source: 'superstream',
            target: 'dataPlane-' + dataPlan.id,
            style: { stroke: '#5A4FE5', strokeWidth: 2 },
            animated: false
        });

        connections.forEach((connection) => {
            const associated_clients_count = connection?.associated_clients_count || 0;

            nodes.push({
                id: 'kafka-' + connection.id,
                type: 'kafka',
                data: {
                    label: connection.name,
                    connections: associated_clients_count
                }
            });
            edges.push({
                source: 'dataPlane-' + dataPlan.id,
                target: 'kafka-' + connection.id,
                style: { stroke: '#5A4FE5', strokeWidth: 2 },
                animated: false
            });

            if (associated_clients_count > 0) {
                nodes.push({
                    id: 'total-clients-' + connection.id,
                    type: 'app',
                    data: {
                        label: `${associated_clients_count?.toLocaleString('en-US')} clients`,
                        orphanClients: false,
                        onClick: (e) => {
                            e.stopPropagation();
                            handleClickOnClientNode(connection.id);
                        }
                    }
                });
                edges.push({
                    source: 'kafka-' + connection.id,
                    target: 'total-clients-' + connection.id,
                    style: { stroke: '#5A4FE5', strokeWidth: 2 },
                    animated: false
                });
            }
        });
    });

    listOfDataPlanes.forEach((dataPlan) => {
        if (dataPlan?.orphan_clients_count > 0) {
            nodes.push({
                id: 'total-orphan-clients' + dataPlan.id,
                type: 'app',
                data: {
                    label: `${dataPlan?.orphan_clients_count?.toLocaleString('en-US')} orphan clients`,
                    orphanClients: true,
                    onClick: (e) => {
                        e.stopPropagation();
                        handleOrphanClients(dataPlan?.env_name);
                    }
                }
            });
            edges.push({
                source: 'dataPlane-' + dataPlan.id,
                target: 'total-orphan-clients' + dataPlan.id,
                style: { stroke: '#5A4FE5', strokeWidth: 2 },
                animated: false
            });
        }
    });

    const layoutGraph = () => {
        const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

        g.setGraph({
            rankdir: 'LR',
            nodesep: 200,
            edgesep: 100,
            ranksep: 350,
            ranker: 'tight-tree',
            align: 'DR'
        });

        edges.forEach((edge) => g.setEdge(edge.source, edge.target));
        nodes.forEach((node) => g.setNode(node.id, node));

        Dagre.layout(g);

        return {
            nodes: nodes.map((node) => {
                const { x, y } = g.node(node.id);
                return { ...node, position: { x, y } };
            }),
            edges
        };
    };

    return layoutGraph();
};

export default formattedData;
