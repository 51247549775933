import AuthLayout from 'layouts/authLayout';
import Heading from 'components/heading';
import UserItem from 'layouts/managementLayout/components/userItem';
import {
    SESSION_STORAGE_ACCOUNT_DATA,
    SESSION_STORAGE_ACCOUNT_ID,
    SESSION_STORAGE_ACTIVATION_TOKEN,
    SESSION_STORAGE_ERROR_MESSAGE,
    SESSION_STORAGE_JWT,
    SESSION_STORAGE_LOGIN_PROVIDER,
    SESSION_STORAGE_USER_UNDER_ASSESSMENT
} from 'utils/localStorageConsts';
import { apiEndpoints } from 'services/apiEndpoints';
import React, { useContext } from 'react';
import { Context } from 'hooks/store';
import { useHttpRequest } from 'services/http';
import { useNavigate } from 'react-router-dom';
import { AuthService, auth } from 'services/firebase';
import router from 'router';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';
import { FaArrowLeftLong } from 'react-icons/fa6';
import Button from 'components/button';
import { useAuth0 } from '@auth0/auth0-react';
import { signOut } from 'firebase/auth';

const SelectAccount = () => {
    const data = sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA);
    const accounts = JSON.parse(data)?.accounts;
    const [state, dispatch] = useContext(Context);
    const currentUser = state?.currentUser;
    const httpRequest = useHttpRequest();
    const navigate = useNavigate();
    const { getIdTokenClaims, logout, isAuthenticated } = useAuth0();

    const handleLogout = async (error = '') => {
        dispatch({ type: 'SET_LOADING', payload: true });
        if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO' && isAuthenticated) {
            try {
                AuthService.clearLocalStorage(error === '');
                error !== '' && sessionStorage.setItem(SESSION_STORAGE_ERROR_MESSAGE, error);
                await logout();
                setTimeout(() => {
                    dispatch({ type: 'SET_LOADING', payload: false });
                }, 1000);
            } catch (error) {
                console.error('Logout failed', error);
            }
        } else
            try {
                await signOut(auth);
                setTimeout(() => {
                    AuthService.logout(error);
                    dispatch({ type: 'SET_LOADING', payload: false });
                }, 1000);
            } catch (error) {
                console.error('Sign out failed', error);
            }
    };

    if (!accounts || sessionStorage.getItem(SESSION_STORAGE_JWT)) return navigate(router.dashboard);

    const handleSignIn = async (accountId) => {
        let sso_token = null;
        if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO') {
            sso_token = await getIdTokenClaims();
            sso_token = token.__raw;
        }
        const token = await currentUser.getIdToken(true);
        await httpRequest('POST', apiEndpoints.SIGN_IN, { firebase_id_token: token, sso_id_token: sso_token, account_id: accountId })
            .then(async (res) => {
                if (res?.data && res.data?.jwt) {
                    sessionStorage.setItem(SESSION_STORAGE_JWT, res.data?.jwt);
                    sessionStorage.setItem(SESSION_STORAGE_ACTIVATION_TOKEN, res.data?.activation_token);
                    sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_ID, res.data?.account_id);
                    sessionStorage.setItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT, res.data?.under_assessment?.toString());

                    dispatch({ type: 'SET_AUTHENTICATION', payload: true });
                    navigate(router.dashboard);
                }
            })
            .catch((error) => {
                handleLogout('Server error, please contact with support!');
            });
    };

    return (
        <AuthLayout>
            <div className="select-account-wrapper">
                <Heading fontSize={24} fontWeight={600}>
                    Choose an account
                </Heading>
                <div className="select-account-items">
                    {accounts.map((user, userIndex) => {
                        return (
                            <div className="select-account" key={userIndex} onClick={() => handleSignIn(user.id)}>
                                <UserItem username={user.name} email={user.id} />
                            </div>
                        );
                    })}
                </div>
                <Button
                    onClick={() => handleLogout()}
                    customClassName="ms-button"
                    placeholder={<CustomButtonWrapper icon={<FaArrowLeftLong color="var(--text-color)" />} text="Go Back" withArrow={false} />}
                />
            </div>
        </AuthLayout>
    );
};

export default SelectAccount;
