import "./style.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import Modal from "components/modal";
import Button from "components/button";
import { ReactComponent as RiseIcon } from 'assets/images/jobs/riseJobCreation.svg';
import { ReactComponent as ViewIcon } from 'assets/images/jobs/viewJobsCreation.svg';
import { useNavigate } from "react-router-dom";
import { IoMdCheckboxOutline } from "react-icons/io";

const NewJobModal = (
    {
        openNewJobModal,
        setOpenNewJobModal,
        pendingTask,
    }
) => {
    const navigate = useNavigate();

    const taskTitles = {
        'remove_inactive_cg': 'Optimization: Removing Inactive Consumer Groups',
        'remove_inactive_topic': 'Optimization: Removing Inactive Topics',
        'reduce_payload': 'Optimization: Reducing Payload Size',
        'compress_payload': 'Client configuration: Compressing Payload',
        'remove_inactive_empty_topic': 'Optimization: Removing Inactive Empty Topics',
    };

    return (
        <Modal
            clickOutside={() => setOpenNewJobModal(false)}
            isModalOpen={openNewJobModal}
            className={"creation-modal"}
        >
            <div className="creation-modal">
                <div className="creation-modal-left">

                </div>
                <div className="creation-modal-right">
                    <div className="top">
                        <div className="header">
                            <div className="icon">
                                <IoMdCheckboxOutline color={"#5A4FE5"} fontSize={20} />
                            </div>
                            <div className="title">A new job has been created</div>
                        </div>
                        <div className="subtitle"></div>
                    </div>
                    <div className="middle">
                        <div className="action-card">
                            <div className="action-card-left">
                                <div className="label">
                                    {taskTitles[pendingTask?.type]}
                                </div>
                                <div className="sublabel">
                                    <div className="text">
                                        <ul>
                                            <li>
                                                {pendingTask?.title || pendingTask?.data}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="action-card-right">
                            </div>
                        </div>

                    </div>
                    <div className="bottom">
                        <div className="left">
                            {/*<Button
                                typeOfButton={"text"}
                                placeholder={"View all jobs"}
                                onClick={() => navigate("/jobs")}
                                customClassName={"btn btn-sp-jobs"}
                                icon={<ViewIcon color={"#475467"}/>}
                                style={{display: "flex", alignItems: "center"}}
                            />*/}
                        </div>
                        <div className="right">
                            <Button
                                placeholder={"Close"}
                                onClick={() => setOpenNewJobModal(false)}
                                customClassName={"btn btn-sp-secondary"}
                            />
                            <Button
                                placeholder={"View all jobs"}
                                onClick={() => navigate("/jobs")}
                                customClassName={"btn btn-sp-primary"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default NewJobModal;