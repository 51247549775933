import './style.scss';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Select, Tabs } from 'antd';
import { Context } from 'hooks/store';
import { IoIosArrowDown } from 'react-icons/io';
import { FaArrowLeft } from 'react-icons/fa';
import pathDomains from 'router';
import ClusterSummary from './components/clusterSummary';
import Clients from './components/clients';
import TodoList from 'components/todoList';
import RefreshButton from 'components/refreshButton';
import Auditing from 'pages/auditing';
import Configuration from './components/configuration';
import { getIcon } from 'services/genericServices';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import SearchComponent from '../asyncJobs/components/search';
import Filter from '../../components/todoList/components/filter';

const tabs = ['summary', 'to-do', 'audit', 'clients', 'configuration'];

const ClusterOverview = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const summaryRef = useRef();
    const todoRef = useRef();
    const auditRef = useRef();
    const clientsRef = useRef();
    const configurationRef = useRef();
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [selectedConnectionID, setSelectedConnectionID] = useState(null);
    const [selectedConnectionName, setSelectedConnectionName] = useState(null);
    const [selectedConnectionType, setSelectedConnectionType] = useState(null);
    const [activeKey, setActiveKey] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [summaryData, setSummaryData] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const refreshRef = useRef(null);

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1];
        setSelectedConnectionID(connectionId);
        const connection = state?.connections?.find((connection) => connection?.id === Number(connectionId));
        setSelectedConnectionName(connection?.name);
        setSelectedConnectionType(connection?.type);
    }, [state?.connections, location.pathname]);

    useEffect(() => {
        const getTabState = searchParams.get('tab');
        if (getTabState) {
            setActiveKey((tabs.indexOf(getTabState) + 1).toString());
        } else {
            setSearchParams({ tab: 'summary' });
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        selectedConnectionID && getConnectionSummary();
    }, [selectedConnectionID]);

    const onChange = (key) => {
        const currentTab = tabs[key - 1];
        setSearchParams({ tab: currentTab });
        setActiveKey(key);
    };

    const handleRefresh = () => {
        setIsLoading(true);
        switch (activeKey) {
            case '1':
                summaryRef.current.handleRefresh();
                break;
            case '2':
                todoRef.current.handleRefresh();
                break;
            case '3':
                auditRef.current.handleRefresh();
                break;
            case '4':
                clientsRef.current.handleRefresh();
                break;
            case '5':
                configurationRef.current.handleRefresh();
                break;
            default:
                setIsLoading(false);
                break;
        }
    };

    const items = [
        {
            key: '1',
            label: 'Summary',
            children: (
                <div className="display-container">
                    <ClusterSummary switchToTodo={() => onChange('2')} connection={selectedConnectionID} ref={summaryRef} doneRefresh={() => setIsLoading(false)} />
                </div>
            )
        },
        {
            key: '2',
            label: summaryData?.available_tasks > 0 ? <>To-do ({summaryData?.available_tasks})</> : 'To-do',
            children: (
                <div className="display-container todo-list">
                    <TodoList
                        height="calc( 100vh - 220px )"
                        title={''}
                        connection_id={selectedConnectionID}
                        ref={todoRef}
                        doneRefresh={() => setIsLoading(false)}
                        hideTopBar={true}
                    />
                </div>
            )
        },
        {
            key: '3',
            label: 'Audit',
            children: (
                <div className={"auditing-wrapper"}>
                    <Auditing
                        tableHeight={"calc(100vh - 185px)"}
                        connection={selectedConnectionID}
                        ref={refreshRef}
                        doneRefresh={() => setIsLoading(false)}
                    />
                </div>
            )
        },
        {
            key: '4',
            label: 'Clients',
            children: (
                <div className="display-container">
                    <Clients connection={selectedConnectionID} ref={clientsRef} doneRefresh={() => setIsLoading(false)} />
                </div>
            )
        },
        {
            key: '5',
            label: 'Configuration',
            children: <Configuration connection={selectedConnectionID} ref={configurationRef} doneRefresh={() => setIsLoading(false)} />
        }
    ];

    const getConnectionSummary = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_CONNECTIONS_BY_ID, {}, {}, { connection_id: selectedConnectionID });
            setSummaryData(data?.connection);
        } catch (error) {}
    };

    return (
        <div className="management-layout-container">
            <div className="cluster-overview">
                <div className="cluster-overview-top">
                    <div className="cluster-overview-top-left">
                        <span className="cluster-name">
                            <div className="back-title">
                                <FaArrowLeft alt="Back" onClick={() => navigate(pathDomains.connectedClusters)} className="back-icon" color="var(--text-color)" />
                                <h1>{selectedConnectionName}</h1>
                            </div>
                            {summaryData?.report_status === 'initial' && (
                                <span className="initial-badge">
                                    <label className="initial-badge-tag">Initial analysis</label>
                                    <label>Results may change</label>
                                </span>
                            )}
                        </span>
                    </div>
                    {state?.connections?.length > 0 && (
                        <div className="cluster-overview-top-right">
                            <Select
                                suffixIcon={<IoIosArrowDown />}
                                id={'connection'}
                                placeholder={
                                    <span className="filter-icons">
                                        {getIcon(selectedConnectionType, state?.darkMode)}
                                        <p>{selectedConnectionName}</p>
                                    </span>
                                }
                                options={state?.connections?.map((connection) => ({
                                    label: connection?.name,
                                    value: connection?.id
                                }))}
                                onChange={(_, record) => {
                                    const currentTab = searchParams.get('tab');
                                    setSelectedConnectionID(record?.value);
                                    navigate(`${pathDomains.connectedClusters}/${record?.value}?tab=${currentTab}`, { replace: true });
                                }}
                                value={selectedConnectionID}
                                labelRender={() => (
                                    <span className="filter-icons">
                                        {getIcon(selectedConnectionType, state?.darkMode)}
                                        <p>{selectedConnectionName}</p>
                                    </span>
                                )}
                                style={{ width: '220px', height: '40px' }}
                            />
                            {activeKey === '2' && (
                                <>
                                    <div style={{ border: '1px solid var(--button-border-color)', borderRadius: '32px' }}>
                                        <SearchComponent
                                            style={{
                                                width: 300,
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between'
                                            }}
                                            placeholder="Start typing here..."
                                        />
                                    </div>
                                    <Filter buttonStyle={{ borderRadius: 32 }} hideConnectionNames={true} />
                                </>
                            )}
                            <RefreshButton onClick={handleRefresh} isLoading={isLoading} />
                        </div>
                    )}
                </div>
            </div>
            <div className="cluster-overview-body">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} activeKey={activeKey} />
            </div>
        </div>
    );
};

export default ClusterOverview;
