import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import { Form } from 'antd';
import Notification from 'components/notifications';
import Modal from 'components/modal';
import Button from 'components/button';
import Loader from 'components/loader';
import ConfigItem from './components/configItem';
import ApplyClustersModal from './components/applyClustersModal';
import { ReactComponent as KafkaIcon } from 'assets/images/sidebar/kafka.svg';
import { Context } from 'hooks/store';
import { sizeToByes, timeToMilliseconds } from 'services/valueConvertor';

const configurationOptimizationItems = [
    {
        name: 'topic_protection_regex',
        title: 'Topic protection',
        description: 'Protected topics cannot be deleted or affected by any type of optimization.',
        type: 'text',
        label: 'Use explicit names and/or wildcards',
        defaultValue: '',
        regex: true,
        placeholder: '^(name$|prefix.*|.*suffix)$'
    },
    {
        name: 'inactive_skewed_topics_setting',
        title: 'Topic inactivity detection',
        description:
            'Set the time after which a topic is considered inactive if no reads/writes are made to it. The default is 3 days, but you can adjust this period to your business logic.',
        type: 'number',
        label: 'Needed days to declare inactive (2-25)',
        min: 2,
        max: 25,
        defaultValue: 3
    },
    {
        name: 'inactive_cgs_setting_days',
        title: 'Consumer Groups inactivity detection',
        description:
            'Set the time after which a consumer group is considered inactive if no message has been consumed. The default is 3 days, but you can adjust this period according to your business logic.',
        type: 'number',
        label: 'Needed days to declare inactive (2 - 25)',
        min: 2,
        max: 25,
        defaultValue: 3
    },
    {
        name: 'inactive_cgs_setting_ignore_non_empty_groups',
        title: 'Inactive Consumer Groups: filter out non-empty groups',
        type: 'tuggle',
        defaultValue: false
    },
    {
        name: 'json_uncompressed_topics_setting',
        title: 'Uncompressed topic / Topics with JSON events detection',
        description:
            'Set the time period in which it can be determined whether the topic contains JSONs or uncompressed messages. The default is 3 days, but you can adjust this period according to your business logic.',
        type: 'number',
        label: 'Needed days to declare (2 - 25)',
        min: 2,
        max: 25,
        defaultValue: 3
    }
];

const configurationRetentionItems = [
    {
        name: 'topic_retention_setting',
        title: 'Maximum Permitted Retention Policy',
        description: 'Superstream will detect and highlight topics that exceed the retention policy.',
        defaultValue: {
            bytes: -1,
            time_ms: -1
        },
        type: 'retention',
    }
];

const Configuration = forwardRef(({ connection, doneRefresh }, ref) => {
    const httpRequest = useHttpRequest();
    const location = useLocation();
    const [state, dispatch] = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [selectedConnectionID, setSelectedConnectionID] = useState(null);
    const [connectionSettings, setConnectionSettings] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [connectionModal, setConnectionModal] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [configurationsType, setConfigurationsType] = useState('optimizations');
    const [configurationItems, setConfigurationItems] = useState(configurationOptimizationItems);
    const [retentionUnits, setRetentionUnits] = useState({ size: 'b', time: 'ms' });
    const [configForm] = Form.useForm();

    const configItemRefs = useRef([]);

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            fetchData();
        }
    }));

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1];
        setSelectedConnectionID(connectionId);
    }, [connection, location.pathname]);

    useEffect(() => {
        setConfigurationsType('optimizations');
    }, [location.search]);

    useEffect(() => {
        fetchData();
    }, [selectedConnectionID]);

    const fetchData = async () => {
        if (!selectedConnectionID) return;

        setLoading(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.GET_CONNECTION_SETTINGS, {}, {}, selectedConnectionID ? { connection_id: selectedConnectionID } : {});
            setConnectionSettings(response);
            setFormValues(response);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
            doneRefresh();
        }
    };

    const updateFormValues = (value) => {
        if (!value) return;
        const isNumber = configurationItems?.find((item) => item?.name === Object.keys(value)[0])?.type === 'number';
        if (Object?.keys(value)[0] === 'bytes') {
            setFormValues({
                ...formValues,
                topic_retention_setting: { ...formValues.topic_retention_setting, [Object.keys(value)[0]]: sizeToByes(Object.values(value)[0], retentionUnits?.size) }
            });
        } else if (Object?.keys(value)[0] === 'time_ms') {
            setFormValues({
                ...formValues,
                topic_retention_setting: {
                    ...formValues.topic_retention_setting,
                    [Object.keys(value)[0]]: timeToMilliseconds(Object.values(value)[0], retentionUnits?.time)
                }
            });
        } else setFormValues({ ...formValues, [Object.keys(value)[0]]: isNumber ? Number(Object.values(value)[0]) : Object.values(value)[0] });
    };

    const updateRetentionFormValues = (value, units, type) => {
        if (type === 'bytes') {
            setFormValues({
                ...formValues,
                topic_retention_setting: { ...formValues.topic_retention_setting, bytes: sizeToByes(value, units?.size) }
            });
        } else {
            setFormValues({
                ...formValues,
                topic_retention_setting: { ...formValues.topic_retention_setting, time_ms: timeToMilliseconds(value, units?.time) }
            });
        }
    };

    const validateDisabled = () => {
        if (!formValues || !connectionSettings) return;
        formValues.topic_protection_full_names = [];
        const isUpdated =
            configurationItems?.some((item) => formValues[item?.name] !== connectionSettings[item?.name]) ||
            formValues?.topic_retention_setting?.bytes !== connectionSettings?.topic_retention_setting?.bytes ||
            formValues?.topic_retention_setting?.time_ms !== connectionSettings?.topic_retention_setting?.time_ms;
        const allValid = !configItemRefs?.current?.every((ref) => {
            ref && ref?.validate();
        });
        setDisabled(!isUpdated || !allValid);
    };

    useEffect(() => {
        validateDisabled();
    }, [formValues, connectionSettings]);

    const updateConnectionSettings = async (connectionsList) => {
        if (!selectedConnectionID) return;
        setLoadingUpdate(true);
        formValues.cg_lag_setting = 500;
        const body = { ...formValues, connection_ids: connectionsList };
        try {
            await httpRequest('POST', apiEndpoints.SET_CONNECTION_SETTINGS, body, {}, {});
            Notification('success', 'The configuration parameters have been successfully updated. ', 15);
        } catch (error) {
            Notification('error', 'Failed updating configuration. ', 15);
            setLoadingUpdate(false);
        } finally {
            fetchData();
            setLoadingUpdate(false);
            setConnectionModal(false);
        }
    };

    return loading ? (
        <Loader background={false} isFullHeight={true} />
    ) : (
        <div className="configuration-page">
            <div>
                <div className="configuration-page-header">
                    <div
                        className={`ms-badge ${configurationsType === 'optimizations' ? 'primary' : 'secondary'}`}
                        onClick={() => setConfigurationsType('optimizations')}
                    >
                        optimizations
                    </div>
                    <div className={`ms-badge ${configurationsType === 'retention' ? 'primary' : 'secondary'}`} onClick={() => setConfigurationsType('retention')}>
                        Retention policy
                    </div>
                </div>
                <div className="configuration-page-content">
                    <Form form={configForm} validateTrigger={'onChange'} onValuesChange={(value) => updateFormValues(value)}>
                        {configurationsType === 'optimizations' &&
                            configurationItems?.map((item, index) => (
                                <ConfigItem
                                    key={item.name}
                                    {...item}
                                    initialValue={connectionSettings[item?.name] || null}
                                    configForm={configForm}
                                    ref={(el) => (configItemRefs.current[index] = el)}
                                />
                            ))}
                        {configurationsType === 'retention' &&
                            configurationRetentionItems?.map((item, index) => (
                                <ConfigItem
                                    key={item.name}
                                    {...item}
                                    initialValue={connectionSettings[item?.name] || null}
                                    configForm={configForm}
                                    ref={(el) => (configItemRefs.current[index] = el)}
                                    updateNoRetention={(value) => updateFormValues(value)}
                                    updateRetentionUnits={(value, units, type) => {
                                        console.log(value, units, type);
                                        setRetentionUnits(units);
                                        updateRetentionFormValues(value, units, type);
                                    }}
                                />
                            ))}
                    </Form>
                </div>
            </div>
            <div className="configuration-page-bottom">
                <Button
                    onClick={() => (state?.connections?.length > 1 ? setOpenModal(true) : updateConnectionSettings([Number(selectedConnectionID)]))}
                    typeOfButton="text"
                    customClassName={'add-connector-placeholder-bottom-button'}
                    placeholder="Apply"
                    loading={loadingUpdate}
                    // disabled={isDisabled}
                />
            </div>
            <Modal isModalOpen={openModal} clickOutside={() => setOpenModal(false)}>
                <div className="todo-list-confirmation-modal">
                    <div className="body">
                        <div className="icon">
                            <KafkaIcon size={20} />
                        </div>
                        <h2>Apply configuration</h2>
                        <p>Do you want to apply this to other clusters?</p>
                    </div>
                    <div className="footer">
                        <Button
                            customClassName="modal-btn modal-btn-secondary"
                            placeholder="No"
                            onClick={() => {
                                setOpenModal(false);
                                updateConnectionSettings([Number(selectedConnectionID)]);
                            }}
                        />
                        <Button
                            customClassName="modal-btn modal-btn-primary"
                            placeholder="Yes"
                            onClick={() => {
                                setOpenModal(false);
                                setConnectionModal(true);
                            }}
                            loading={loadingUpdate}
                        />
                    </div>
                </div>
            </Modal>
            <ApplyClustersModal
                isModalOpen={connectionModal}
                closeModal={() => setConnectionModal(false)}
                icon={<KafkaIcon size={20} />}
                applyConfiguration={(connectionsList) => updateConnectionSettings(connectionsList)}
                loading={loadingUpdate}
                connection={connection}
            />
        </div>
    );
});

export default Configuration;
