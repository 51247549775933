import "./style.scss";
import { FiSearch } from "react-icons/fi";
import Input from "components/input";
import { useContext } from "react";
import { FilterContext } from "hooks/store";

const SearchComponent = ({ placeholder="Search", style }) => {
    const [state, dispatch] = useContext(FilterContext);

    return (
        <div className="search-component" style={style}>
            <div className="search-component-icon">
                <FiSearch color="#737373" />
            </div>
            <div className="search-component-input">
                <Input
                    onChange={(e) => dispatch({type: 'SET_SEARCH_VALUE', payload: e.target.value})}
                    value={state?.searchValue}
                    variant="borderless"
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}

export default SearchComponent;