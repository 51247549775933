import './style.scss';

const InfoCard = ({ icon, count, label }) => {
    return (
        <div className="info-card">
            <div className="info-card-left">
                <div className="info-card-icon">{icon}</div>
            </div>
            <div className="info-card-right">
                <div className="info-card-title">{count?.toLocaleString()}</div>
                <div className="info-card-subtitle">{label}</div>
            </div>
        </div>
    );
};

export default InfoCard;
