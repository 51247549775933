import './style.scss';

import React, { useState, useContext, useMemo, useEffect } from 'react';
import pathDomains from 'router';
import { BiError } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import ConnectionItem from './components/ConncetionItem';
import Button from 'components/button';
import Table from 'components/table';
import { Context } from 'hooks/store';
import AddConnectorPlaceholder from 'components/addConnectorPlaceholder';
import ConnectorModal from '../../components/Modals/ConnectorModal';
import Loader from 'components/loader';
import { LOCAL_STORAGE_DARK_MODE } from 'utils/localStorageConsts';
import { ReactComponent as AddConnectorPlaceholderIcon } from 'assets/images/documents.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import useTableDynamicPageSize from 'hooks/useTableDynamicPageSize';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import { parsingDate, uuidv4 } from 'services/valueConvertor';
import { getIcon } from 'services/genericServices';
import StatusBadge from './components/statusBadge';
import RefreshButton from 'components/refreshButton';
import TooltipComponent from 'components/tooltip/tooltip';

const ManageConnections = () => {
    const [state, dispatch] = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const darkMode = localStorage.getItem(LOCAL_STORAGE_DARK_MODE) === 'dark';
    const pageSize = useTableDynamicPageSize();
    const navigate = useNavigate();
    const httpRequest = useHttpRequest();
    const location = useLocation();

    useEffect(() => {
        getAllConnections();
    }, [location.pathname]);

    useEffect(() => {
        const runningConnection = state?.connections?.find((connection) => connection?.report_status === 'running');
        if (runningConnection) {
            const interval = setInterval(() => {
                getAllConnections();
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [state?.connections]);

    const getAllConnections = async () => {
        setIsLoading(true);
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ALL_CONNECTIONS);
            dispatch({ type: 'SET_CONNECTIONS', payload: data?.connections || [] });
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const renderMergedColumn = (record) => {
        if (record?.report_status === 'running') {
            return {
                children: <StatusBadge type={record?.report_status} creation_date={record?.last_report_time} sample_progress={record?.progress_bar} />,
                props: {
                    colSpan: 3
                }
            };
        }
        return { children: parsingDate(record?.last_report_time, true), props: {} };
    };

    const renderMergedColumnInitial = (record) => {
        return {
            children: (
                <StatusBadge
                    type={record?.report_status}
                    creation_date={record?.last_report_time}
                    sample_progress={record?.progress_bar}
                    eta={record?.eta_next_report_time}
                />
            ),
            props: {
                colSpan: 2
            }
        };
    };

    const tableColumns = [
        {
            title: 'Connection name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <span className="cluster-name">
                    {getIcon(record?.type, darkMode)}
                    {record?.name}
                    {!record?.valid && (
                        <TooltipComponent text={record?.invalid_reason}>
                            <BiError color="var(--failed-color)" />
                        </TooltipComponent>
                    )}
                </span>
            ),
            sorter: (a, b) => a?.cluster_name?.localeCompare(b?.cluster_name)
        },
        {
            title: 'Engine',
            dataIndex: 'data_plane',
            key: 'data_plane',
            render: (data_plane) => data_plane,
            sorter: (a, b) => a?.data_plane?.localeCompare(b?.data_plane)
        },
        {
            title: 'Creation date',
            dataIndex: 'updated_at',
            key: 'created_at',
            render: (updated_at) => parsingDate(updated_at, true)
            // sorter: (a, b) => a?.cluster_name?.localeCompare(b?.cluster_name)
        },
        {
            title: 'Last sync',
            dataIndex: 'last_report_time',
            key: 'last_report_time',
            render: (last_report_time, record) => renderMergedColumn(record)
            // sorter: (a, b) => a?.cluster_name?.localeCompare(b?.cluster_name)
        },
        {
            title: 'ETA for the next sync',
            align: 'center',
            key: 'eta_next_report_time',
            render: (_, record) => {
                if (record?.report_status === 'running') {
                    return {
                        props: {
                            colSpan: 0
                        }
                    };
                }
                return record?.report_status === 'ready' ? (
                    <StatusBadge type={record?.report_status} updated_at={record?.updated_at} sample_progress={record?.progress_bar} eta={record?.eta_next_report_time} />
                ) : (
                    renderMergedColumnInitial(record)
                );
            }
        },
        {
            title: 'Open tasks',
            dataIndex: 'open_tasks',
            key: 'open_tasks',
            align: 'center',
            render: (open_tasks, record) => {
                if (record?.report_status === 'running' || record?.report_status === 'initial') {
                    return {
                        props: {
                            colSpan: 0
                        }
                    };
                }
                return <span className="open_tasks_counter">{open_tasks}</span>;
            },
            sorter: (a, b) => a?.open_tasks - b?.open_tasks
        },
        {
            key: 'actions',
            title: '',
            render: (_, record) => <ConnectionItem key={record.id} connection={record} removeConnection={removeitem} />
        }
    ];

    const columns = useMemo(() => tableColumns, [state?.darkMode, state?.connections]);

    useEffect(() => {
        const uniqueConnections = state?.connections.map((connection, index) => ({
            ...connection,
            key: uuidv4()
        }));
        setDataSource(uniqueConnections);
    }, [state?.connections]);

    const removeitem = (id) => {
        dispatch({ type: 'SET_CONNECTIONS', payload: state?.connections?.filter((connection) => connection.id !== id) });
    };

    return (
        <div className="manage-connections-container management-layout-container">
            <div className="manage-connections-container-top">
                <span className="manage-connections-container-header">
                    <h1>Connected clusters</h1>
                    <div className="add-connector-placeholder-bottom">
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            typeOfButton="text"
                            placeholder={
                                <div className="add-connector-placeholder-bottom-button">
                                    <FiPlus color="var(--color-white)" fontSize={18} />
                                    Connect a new cluster
                                </div>
                            }
                        />
                        <RefreshButton onClick={getAllConnections} isLoading={isLoading} />
                    </div>
                </span>
            </div>
            {state?.isLoadingConnections && (
                <div className="loading-connections">
                    <Loader background={false} darkMode={state?.darkMode || darkMode} />
                </div>
            )}
            {!state?.isLoadingConnections && state?.connections?.length > 0 && (
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={dataSource?.length > pageSize ? { pageSize, showSizeChanger: false } : false}
                    bordered={false}
                    onCustomRowClick={(row) => navigate(pathDomains.connectedClusters + '/' + encodeURI(row?.id), { replace: true })}
                    showSorterTooltip={false}
                    rowHoverBgColor="var(--table-row-even) !important"
                />
            )}
            {!state?.isLoadingConnections && state?.connections?.length === 0 && (
                <AddConnectorPlaceholder
                    img={<AddConnectorPlaceholderIcon />}
                    text="Get Started with Superstream"
                    btnText="Connect a new cluster"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                />
            )}
            <ConnectorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </div>
    );
};

export default ManageConnections;
