import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
    SESSION_STORAGE_ACCOUNT_DATA,
    SESSION_STORAGE_ACCOUNT_ID,
    SESSION_STORAGE_ACTIVATION_TOKEN,
    SESSION_STORAGE_ERROR_MESSAGE,
    SESSION_STORAGE_JWT,
    SESSION_STORAGE_LOGIN_PROVIDER
} from 'utils/localStorageConsts';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = process.env.REACT_APP_ENVIRONMENT !== 'onprem' && initializeApp(firebaseConfig);

app &&
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_RECAPTCHA_PUBLIC_KEY),
        isTokenAutoRefreshEnabled: true
    });

const auth = process.env.REACT_APP_ENVIRONMENT !== 'onprem' && getAuth(app);

const AuthService = (function () {
    const clearLocalStorage = (includeError = true) => {
        sessionStorage.removeItem(SESSION_STORAGE_JWT);
        sessionStorage.removeItem(SESSION_STORAGE_ACCOUNT_ID);
        sessionStorage.removeItem(SESSION_STORAGE_ACCOUNT_DATA);
        sessionStorage.removeItem(SESSION_STORAGE_ACTIVATION_TOKEN);
        sessionStorage.removeItem(SESSION_STORAGE_LOGIN_PROVIDER);
        includeError && sessionStorage.removeItem(SESSION_STORAGE_ERROR_MESSAGE);
    };

    const logout = async (error = '', withRedirect = true) => {
        clearLocalStorage();
        if (withRedirect) {
            if (error !== '') {
                window.location.replace(`/signin/?errorMessage=${error}`, { replace: true });
            } else {
                window.location.replace('/signin', { replace: true });
            }
        }
    };

    return {
        clearLocalStorage,
        logout
    };
})();
export { AuthService, auth };
