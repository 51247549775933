import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListItem from '../../listItem';
import { useAnimate, usePresence } from 'framer-motion';
import { Context } from 'hooks/store';
import { GoArrowRight } from "react-icons/go";

const TodoListItemContainer = ({ todo, fetchByGroupTasks, status, displayCluser, handleItemRemove  }) => {
    const [state] = useContext(Context);
    const [isPresent, safeToRemove] = usePresence();
    const [scope, animate] = useAnimate();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isPresent) {
            const exitAnimation = async () => {
                await animate(scope.current, { opacity: 0, height: 0 });

                safeToRemove();
            };

            void exitAnimation();
        }
    }, [isPresent]);

    const getEngineName = (connection) => {
        return state?.connections?.find((item) => item?.id === connection)?.data_plane;
    };

    return (
        <div style={{display: "flex", gap: 8, flexDirection: "column"}}>
            {displayCluser && (
                <div className="cluster-name-divider">
                    Connection name:
                    <p onClick={() => navigate(`/connected-clusters/${todo?.connection_id}?tab=summary`)}>
                        {` ${todo?.connection_name} (${getEngineName(todo?.connection_id)})`}{' '}
                        <GoArrowRight fontSize={22} />
                    </p>
                </div>
            )}
            <ListItem
                ref={scope}
                key={todo.id}
                openDrawer={fetchByGroupTasks}
                item={todo}
                status={status}
                handleItemRemove={handleItemRemove}
            />
        </div>
    );
};

export default TodoListItemContainer;
