import './style.scss';
import 'reactflow/dist/style.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, { addEdge, Background, Controls, MiniMap, useEdgesState, useNodesState } from 'reactflow';
import nodeTypes from './components/nodes';
import AddConnectorDrawer from './components/addConnectorDrawer';
import { Context } from 'hooks/store';
import { LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED } from 'utils/localStorageConsts';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import FormattedData from './components/formattedData';
import Loader from 'components/loader';
import ConnectorModal from 'components/Modals/ConnectorModal';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from 'services/valueConvertor';
import OrphanClientsDrawer from './components/orphanClientsDrawer';
import Modal from 'components/modal';
import Button from 'components/button';
import { Switch } from 'antd';
import Notification from 'components/notifications';
import debounce from "lodash.debounce";

const connectionLineStyle = { stroke: '#5A4FE5', strokeWidth: 2 };
const snapGrid = [25, 25];
const defaultViewport = { x: 300, y: 300, zoom: 0.8 };

const initialEdges = [{ id: 'e1-2', source: 'superstream', target: 'addDataPlan', animated: false, style: { stroke: '#615EF6', strokeWidth: 2 }, type: 'smoothstep' }];

const SystemView = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [dataPlanModal, setDataPlanModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [, dispatch] = useContext(Context);
    const httpRequest = useHttpRequest();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [orphanClientsDrawerOpen, setOrphanClientsDrawerOpen] = useState(false);
    const [orphanClients, setOrphanClients] = useState([]);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [loadingCompression, setLoadingCompression] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedInitialClient, setSelectedInitialClient] = useState({});
    const [fetchingOrphanClients, setFetchingOrphanClients] = useState(true);

    const onConnect = useCallback((params) => {
        const newEdge = {
            ...params,
            animated: true,
            style: { stroke: 'var(--primary-color)', strokeWidth: 2 }
        };
        setEdges((eds) => addEdge(newEdge, eds));
    }, []);

    const onMoveStart = () => handleSidebarCollapse();
    //const onNodeDragStart = () => handleSidebarCollapse();

    const handleSidebarCollapse = () => {
        dispatch({ type: 'SET_SIDEBAR_STATE', payload: true });
        localStorage.setItem(LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED, 'true');
    };

    const getGraphData = async () => {
        return await httpRequest('GET', apiEndpoints.GET_GRAPH_DATA, {}, {}, {});
    };

    const getOrphanClients = async (env_name) => {
        return await httpRequest('GET', apiEndpoints.GET_ORPHAN_CLIENTS, {}, {}, {env_name});
    };

    const handleOrphanClients = debounce(async (env_name) => {
        setOrphanClientsDrawerOpen(true);
        setFetchingOrphanClients(true);

        const orphanClients = await getOrphanClients(env_name).finally(() => setFetchingOrphanClients(false));

        setOrphanClients(orphanClients);
    }, 300);

    const retrieveData = debounce( async () => {
        const handleClickOnClientNode = (param) => {
            navigate(`/connected-clusters/${param}?tab=clients`);
        };

        setIsLoading(true);
        getGraphData()
            .then((data) => {
                const listOfDataPlanes = [...data?.listOfDataPlanes].sort((a, b) => b?.env_name.localeCompare(a?.env_name));
                const { nodes, edges } = FormattedData(listOfDataPlanes, setDataPlanModal, setIsModalOpen, handleClickOnClientNode, handleOrphanClients);
                setNodes(nodes);
                setEdges(edges.map((edge) => ({ ...edge, id: uuidv4() })));
            })
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(false);
            });
    }, 300);

    useEffect(() => {
        retrieveData();
    }, []);

    const nodeColor = (node) => {
        switch (node.type) {
            case 'dataPlane':
                return 'var(--primary-color)';
            case 'kafka':
                return 'var(--sunglow)';
            case 'superstream':
                return 'var(--greenish-cyan)';
            case 'app':
                return 'var(--badge-success)';
            default:
                return 'var(--placeholder-color)';
        }
    };

    const onCompressionChanged = async () => {
        setLoadingCompression(true);
        try {
            await httpRequest('POST', apiEndpoints.CLIENTS_TOGGLE_COMPRESSION, {
                all_clients: false,
                enable_compression: selectedClient?.compression_enabled,
                client_ids: [selectedClient?.id]
            });
        } catch (error) {
            setLoadingCompression(false);
        } finally {
            setOrphanClients((prevData) =>
                prevData.map((item) => (item.id === selectedClient?.id ? { ...item, compression_enabled: selectedClient?.compression_enabled } : item))
            );
            Notification(
                'success',
                selectedClient?.compression_enabled ? 'Compression has been successfully turned on' : 'Compression has been successfully turned off',
                5
            );
            setLoadingCompression(false);
            setConfirmationModalOpen(false);
            setSelectedClient({});
            setSelectedInitialClient({});
        }
    };

    const handleCompression = (item) => {
        setSelectedInitialClient(item);
        setSelectedClient(item);
        setConfirmationModalOpen(true);
    };

    return isLoading ? (
        <Loader background={false} isFullHeight={true} />
    ) : (
        <div className="system-view">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultViewport={defaultViewport}
                onMoveStart={onMoveStart}
                //onNodeDragStart={onNodeDragStart}
                nodesConnectable={false}
                deleteKeyCode={null}
                fitView
                fitViewOptions={{ maxZoom: 0.8, duration: 1500 }}
                panOnScroll={true}
                maxZoom={1}
                minZoom={-1}
            >
                <Background variant="dots" gap={50} size={2.5} />
                <Controls showInteractive={false} position={'bottom-right'} />
                <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} position="top-right" zoomable pannable />
            </ReactFlow>
            <AddConnectorDrawer dataPlanModal={dataPlanModal} setDataPlanModal={setDataPlanModal} />
            <ConnectorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} sysViewUpdate={retrieveData} />

            <OrphanClientsDrawer
                open={orphanClientsDrawerOpen}
                onClose={() => setOrphanClientsDrawerOpen(false)}
                orphanClients={orphanClients}
                handleCompression={handleCompression}
                loading={fetchingOrphanClients}
            />
            <Modal width="520px" displayButtons={true} clickOutside={() => setConfirmationModalOpen(false)} isModalOpen={confirmationModalOpen}>
                <div className="client-confirmation-modal">
                    <div className="middle">
                        <h2>Configurations</h2>
                        <div className="reduction">
                            <div className="left">
                                <p className="title">Compression</p>
                            </div>
                            <div className="right">
                                <Switch
                                    value={selectedClient?.compression_enabled}
                                    style={{ backgroundColor: selectedClient?.compression_enabled ? 'var(--primary-color)' : 'var(--search-bg)' }}
                                    onChange={() => setSelectedClient({ ...selectedClient, compression_enabled: !selectedClient?.compression_enabled })}
                                />
                            </div>
                        </div>
                        {/* <AnimatePresence initial={false}>
                            {selectedClient?.compression_enabled !== selectedInitialClient?.compression_enabled && (
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                                >
                                    <div className="alert">
                                        <Alert
                                            description="Payload reduction will efficiently transform JSON payloads into binary format, decreasing their size by as much as 75%."
                                            type="error"
                                        />
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence> */}
                    </div>
                    <div className="bottom">
                        <Button customClassName="modal-btn modal-btn-secondary" placeholder="Close" onClick={() => setConfirmationModalOpen(false)} />
                        <Button
                            customClassName="modal-btn modal-btn-primary"
                            placeholder="Confirm"
                            onClick={() => onCompressionChanged()}
                            loading={loadingCompression}
                            disabled={selectedClient?.compression_enabled === selectedInitialClient?.compression_enabled}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SystemView;
