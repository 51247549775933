import React, { createContext, useReducer } from 'react';
import {Reducer, FilterReducer} from './reducer';

export const Context = createContext(null);

export const FilterContext = createContext(null);

export const initialState = {
    isLoadingConnections: true,
    connections: [],
    environments: [],
    currentUser: {},
    isAuthenticated: false,
    isSidebarCollapsed: false,
    newJobIndicator: false,
    isAppLoading: false,
    keysList: [],
};

export const initialFilterState = {
    searchValue: null,
    selectedFilters: {
        tagOptions: [],
        fixable: [],
        groupTypes: [],
        connection_names: []
    },
    resetFilters: false,
    applyFilters: false,
};

export const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const FilterStore = ({ children }) => {
    const [state, dispatch] = useReducer(FilterReducer, initialFilterState);

    return <FilterContext.Provider value={[state, dispatch]}>{children}</FilterContext.Provider>;
}
