import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Auth0ProviderWithHistory from 'auth0-provider-with-history';
import { useContext } from 'react';
import pathDomains from 'router';

import ManageConnections from './pages/manageConnections';
import ForgetPassword from './pages/auth/ForgetPassword';
import SetNewPassword from './pages/auth/SetNewPassword';
import SetupNewProfile from './pages/auth/SetNewProfile';
import Auditing from './pages/auditing';
import AuthHandler from './pages/auth/AuthHandler';
import MainLayout from 'layouts/managementLayout';
import PrivateRoute from './utils/privateRoute';
import PublicRoute from './utils/publicRoute';
import SystemView from './pages/systemView';
import SignIn from './pages/auth/SignIn';
import Layout from './layouts/Layout';
import { Context } from 'hooks/store';
import SelectAccount from './pages/auth/SelectAccount';
import AsyncJobs from './pages/asyncJobs';
import Keys from 'pages/keys';
import Dashboard from './pages/dashboard';
import ClusterOverview from 'pages/clusterOverview';
import Tasks from './pages/tasks';

function App() {
    const [state] = useContext(Context);

    return (
        <BrowserRouter>
            {process.env.REACT_APP_ENVIRONMENT !== 'onprem' ? (
                <Auth0ProviderWithHistory>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route element={<PublicRoute />}>
                                <Route path={pathDomains.signin} element={<SignIn />} />
                                <Route path={pathDomains.forgetPassword} element={<ForgetPassword />} />
                                <Route path={pathDomains.resetPassword} element={<SetNewPassword />} />
                                <Route path={pathDomains.setupProfile} element={<SetupNewProfile />} />
                                <Route path={pathDomains.authHandler} element={<AuthHandler />} />
                            </Route>

                            <Route element={<PrivateRoute />}>
                                <Route path={pathDomains.selectAccount} element={<SelectAccount />} />
                                <Route path="" element={<MainLayout />}>
                                    <Route path={pathDomains.dashboard} element={<Dashboard />} />
                                    <Route path={pathDomains.systemView} element={<SystemView />} />
                                    <Route path={pathDomains.auditing} element={<Auditing />} />
                                    <Route path={`${pathDomains.connectedClusters}/:id`} element={<ClusterOverview />} />
                                    <Route path={pathDomains.connectedClusters} element={<ManageConnections />} />
                                    <Route path={pathDomains.keys} element={<Keys />} />
                                    <Route path={pathDomains.asyncJobs} element={<AsyncJobs />} />
                                    <Route path={pathDomains.tasks} element={<Tasks />} />
                                    <Route path="/" element={<Navigate to={pathDomains.dashboard} replace />} />
                                </Route>
                            </Route>
                            <Route path="*" element={state?.isAuthenticated ? <Navigate to={pathDomains.dashboard} replace /> : <Navigate to="/signin" />} />
                        </Route>
                    </Routes>
                </Auth0ProviderWithHistory>
            ) : (
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route element={<PublicRoute />}>
                            <Route path={pathDomains.signin} element={<SignIn />} />
                        </Route>

                        <Route element={<PrivateRoute />}>
                            <Route path={pathDomains.selectAccount} element={<SelectAccount />} />
                            <Route path="" element={<MainLayout />}>
                                <Route path={pathDomains.dashboard} element={<Dashboard />} />
                                <Route path={pathDomains.systemView} element={<SystemView />} />
                                <Route path={pathDomains.auditing} element={<Auditing />} />
                                <Route path={`${pathDomains.connectedClusters}/:id`} element={<ClusterOverview />} />
                                <Route path={pathDomains.connectedClusters} element={<ManageConnections />} />
                                <Route path={pathDomains.keys} element={<Keys />} />
                                <Route path={pathDomains.asyncJobs} element={<AsyncJobs />} />
                                <Route path={pathDomains.tasks} element={<Tasks />} />
                                <Route path="/" element={<Navigate to={pathDomains.dashboard} replace />} />
                            </Route>
                        </Route>
                        <Route path="*" element={state?.isAuthenticated ? <Navigate to={pathDomains.dashboard} replace /> : <Navigate to="/signin" />} />
                    </Route>
                </Routes>
            )}
        </BrowserRouter>
    );
}

export default App;
