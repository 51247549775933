import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Badge from '../badge';
import ListSubItem from '../listItem/subTask';
import Drawer from '../drawer';
import './style.scss';
import { Spin } from 'antd';
import Button from '../button';
import { AnimatePresence, motion } from 'framer-motion';
import { uuidv4 } from 'services/valueConvertor';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import { convertStorage } from 'services/genericServices';
import EmptyTasksPlaceholder from './components/emptyTasksPlaceholder';
import TodoListItemContainer from './components/todo-list';
import { SESSION_STORAGE_USER_UNDER_ASSESSMENT } from 'utils/localStorageConsts';
import { ReactComponent as LimitedIcon } from 'assets/images/optimization/limited.svg';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../pages/asyncJobs/components/search';
import SearchComponentStateless from 'components/search';
import debounce from 'lodash.debounce';
import { AiOutlineClose } from 'react-icons/ai';
import Filter from './components/filter';
import { FilterContext } from 'hooks/store';
import Loader from '../loader';
import { BiCog } from 'react-icons/bi';

const TodoList = forwardRef(
    (
        {
            maxHeight = '100%',
            height,
            perPage = 10,
            connection_id,
            doneRefresh,
            title = 'To-do list',
            cardTitleStyle,
            cardTopStyle,
            hideTopBar = false,
            mainPage = false
        },
        ref
    ) => {
        const [state, dispatch] = useContext(FilterContext);
        const httpRequest = useHttpRequest();
        const [openDrawer, setOpenDrawer] = useState(false);
        const [todos, setTodos] = useState([]);
        const [initialTodos, setInitialTodos] = useState([]);
        const status = 'available';
        const [isFetching, setIsFetching] = useState(false);
        const navigate = useNavigate();
        const [tasksDetails, setTasksDetails] = useState({});
        const [tasks, setTasks] = useState([]);
        const [searchTasks, setSearchTasks] = useState([]);
        const [initialTasks, setInitialTasks] = useState([]);
        const itemsPerScroll = 50;
        const [drawerWidth, setDrawerWidth] = useState(650);
        const [connectionId, setConnectionId] = useState(connection_id);
        const isUserUnderAssessment = sessionStorage.getItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT) === 'true';
        const topRef = useRef(null);
        const [topHeight, setTopHeight] = useState(0);
        const [taskSearchValue, setTaskSearchValue] = useState('');
        const [activeTaskTab, setActiveTaskTab] = useState('fixable');
        const [countOfTasks, setCountOfTasks] = useState({
            fixable: 0,
            notFixable: 0
        });
        const [selectedItem, setSelectedItem] = useState(null);

        useEffect(() => {
            if (topRef.current) {
                setTopHeight(topRef.current.offsetHeight + 90);
            }

            const handleResize = () => {
                if (topRef.current) {
                    setTopHeight(topRef.current.offsetHeight + 90);
                }
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [tasks]);

        useImperativeHandle(ref, () => ({
            handleRefresh() {
                fetchAggregatedTasks()?.catch();
            }
        }));

        useEffect(() => {
            fetchAggregatedTasks().catch();
        }, [status]);

        const fetchAggregatedTasks = async () => {
            setIsFetching(true);
            try {
                const queryParams = { connection_id, status };
                const response = await httpRequest('GET', apiEndpoints.TASKS_GET_ALL_GROUPS, {}, {}, queryParams);
                const groups = response?.groups
                    ?.map((item) => ({
                        ...item,
                        id: uuidv4()
                    }))
                    .sort((a, b) => (title ? (a?.connection_id - b?.connection_id > 0 ? 1 : -1) : a?.title.localeCompare(b?.title)));
                setTodos(groups);
                setInitialTodos(groups);
            } catch (error) {
            } finally {
                setIsFetching(false);
                connection_id && doneRefresh();
            }
        };

        const fetchByGroupTasks = async (item, activeTab = 'fixable') => {
            setTaskSearchValue('');
            setActiveTaskTab(activeTab);
            setSelectedItem(item);
            setConnectionId(item?.connection_id);
            const queryParams = { connection_id: item?.connection_id, task_type: item?.task_type, task_status: status };
            try {
                const response = await httpRequest('GET', apiEndpoints.TASKS_GET_BY_GROUP, {}, {}, queryParams);
                let tasks = response?.tasks?.tasks;
                const metadata = tasks[0]?.metadata;
                const metadataCount = metadata ? Object.keys(metadata).length : 0;
                setDrawerWidth(metadataCount > 1 ? 700 : 650);

                const sortedTasks =
                    tasks?.sort((a, b) => {
                        const partitionsDifference = (b?.metadata?.partitions?.value ?? 0) - (a?.metadata?.partitions?.value ?? 0);
                        if (partitionsDifference !== 0) {
                            return partitionsDifference;
                        }
                        return (b?.metadata?.potential_saving_in_mb?.value ?? 0) - (a?.metadata?.potential_saving_in_mb?.value ?? 0);
                    }) || [];

                if (response?.tasks) {
                    tasks = sortedTasks;
                }

                const fixableCount = tasks.filter((task) => task?.fixable === true).length;
                const notFixableCount = tasks.filter((task) => typeof task?.fixable === 'undefined' || task?.fixable === false).length;

                setCountOfTasks({
                    fixable: fixableCount,
                    notFixable: notFixableCount
                });

                if (fixableCount === 0 && notFixableCount > 0) setActiveTaskTab('not_fixable');

                if (fixableCount === 0 && notFixableCount > 0) {
                    tasks = tasks.filter((task) => typeof task?.fixable === 'undefined' || task?.fixable === false);
                } else {
                    tasks = tasks.filter((task) => task?.fixable === true);
                }

                setTasksDetails(response?.tasks);
                setInitialTasks(tasks);
                const lazyLoadTasks = tasks.slice(0, itemsPerScroll);
                setTasks(lazyLoadTasks);
                setOpenDrawer(true);
            } catch (error) {
            } finally {
            }
        };

        const handleScroll = (event) => {
            if (tasks.length === initialTasks.length) return;

            const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
            if (scrollTop + clientHeight >= scrollHeight - 200) {
                let nextLoadedItems = [];
                if (taskSearchValue !== '') {
                    nextLoadedItems = searchTasks.slice(0, tasks.length + itemsPerScroll);
                } else {
                    nextLoadedItems = initialTasks.slice(0, tasks.length + itemsPerScroll);
                }
                setTasks(nextLoadedItems);
            }
        };

        const handleTasksSearch = debounce(async (value) => {
            if (value?.length === 0) {
                setTasks(initialTasks.slice(0, itemsPerScroll));
            } else {
                const filteredTasks = initialTasks.filter((task) => task?.data.toLowerCase().includes(value.toLowerCase()));
                const lazyLoadTasks = filteredTasks.slice(0, itemsPerScroll);
                setTasks(lazyLoadTasks);
                setSearchTasks(lazyLoadTasks);
            }
        }, 400);

        const handleSelectedFilters = () => {
            const { selectedFilters } = state;
            const totalFilters = Object.values(selectedFilters).reduce((acc, curr) => acc + curr.length, 0);

            if (totalFilters === 0 && !state?.searchValue) return setTodos(initialTodos);

            if (!state?.applyFilters && !state?.searchValue) return;

            let filteredData = JSON.parse(JSON.stringify(initialTodos));

            if (selectedFilters?.tagOptions?.length > 0) {
                filteredData = filteredData.filter((group) => selectedFilters?.tagOptions.some((tag) => group?.tags.includes(tag)));
            }

            if (selectedFilters?.fixable?.length > 0) {
                filteredData = filteredData.filter((group) => selectedFilters?.fixable.includes(group?.fixable));
            }

            if (selectedFilters?.groupTypes?.length > 0) {
                filteredData = filteredData.filter((group) => selectedFilters?.groupTypes.includes(group?.task_type));
            }

            if (selectedFilters?.connection_names?.length > 0) {
                filteredData = filteredData.filter((group) =>
                    selectedFilters?.connection_names.some((connection) => connection.toLowerCase() === group?.connection_name.toLowerCase())
                );
            }

            const value = state?.searchValue;
            if (value) {
                filteredData = filteredData.filter((todo) => todo?.title.toLowerCase().includes(value.toLowerCase()));
            }

            setTodos(filteredData);

            dispatch({ type: 'SET_APPLY_FILTERS', payload: false });
        };

        useEffect(() => {
            handleSelectedFilters();
        }, [state?.applyFilters, state?.searchValue]);

        useEffect(() => {
            resetFilters();
        }, [state?.resetFilters]);

        const resetFilters = () => {
            const { selectedFilters } = state;
            const totalFilters = Object.values(selectedFilters).reduce((acc, curr) => acc + curr.length, 0);
            if (totalFilters === 0) return;
            dispatch({
                type: 'SET_SELECTED_FILTERS',
                payload: {
                    tagOptions: [],
                    fixable: [],
                    groupTypes: [],
                    connection_names: []
                }
            });
            setTodos(initialTodos);
            dispatch({ type: 'SET_RESET_FILTERS', payload: false });
            dispatch({ type: 'SET_SEARCH_VALUE', payload: '' });
        };

        const handleTaskItemRemove = async (item) => {
            setTodos((prevState) => prevState.filter((i) => i.id !== item.id));
        };

        const handleSubtaskRemove = async (item) => {
            setTasks((prevState) => prevState.filter((i) => i.id !== item.id));
            await fetchByGroupTasks(selectedItem, activeTaskTab).finally(() => {
                countOfTasks?.notFixable + countOfTasks?.fixable === 0 && setOpenDrawer(false);
            });
        };

        return (
            <div className="todo-list">
                {(!hideTopBar || mainPage) && (
                    <div className="card-top" style={cardTopStyle}>
                        {mainPage ? (
                            <div className="async-jobs-heading">
                                <div className="async-jobs-heading-left">
                                    <h1>To-do</h1>
                                </div>
                            </div>
                        ) : (
                            <div className="card-top-left">{title && <h4 style={cardTitleStyle}>{title}</h4>}</div>
                        )}
                        <div className="card-top-right">
                            {initialTodos?.length !== 0 && (
                                <>
                                    <Filter />
                                    <div style={{ border: '1px solid var(--button-border-color)', borderRadius: '32px', height: '38px' }}>
                                        <SearchComponent
                                            style={{
                                                width: 300,
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between'
                                            }}
                                            placeholder="Start typing here..."
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className="card">
                    <div className="card-body" style={{ height: height }}>
                        {isFetching ? (
                            <Loader background={false} />
                        ) : (
                            <Spin spinning={isFetching} delay={300}>
                                <AnimatePresence initial={false}>
                                    {todos?.length === 0 && <EmptyTasksPlaceholder status={initialTodos.length === 0 ? status : 'empty_search'} />}
                                    {todos?.map((todo, index) => {
                                        return (
                                            <TodoListItemContainer
                                                displayCluser={title && (index === 0 || todo?.connection_id !== todos[index - 1]?.connection_id)}
                                                key={todo.id}
                                                todo={todo}
                                                fetchByGroupTasks={fetchByGroupTasks}
                                                status={status}
                                                handleItemRemove={() => handleTaskItemRemove(todo)}
                                            />
                                        );
                                    })}
                                </AnimatePresence>
                            </Spin>
                        )}
                    </div>
                </div>

                <Drawer
                    open={openDrawer}
                    destroyOnClose={true}
                    onClose={() => setOpenDrawer(false)}
                    className="todo-list-drawer-wrapper"
                    styles={{
                        header: { display: 'none' }
                    }}
                    width={drawerWidth}
                >
                    <div className="todo-list-drawer-top" ref={topRef}>
                        <div className="title-wrapper">
                            <div className="title">{tasksDetails?.title}</div>
                            <div style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'center', gap: 5 }}>
                                <Button
                                    onClick={() => navigate(`/connected-clusters/${connectionId}?tab=configuration`)}
                                    placeholder={
                                        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                                            <BiCog />
                                            <div>Configuration</div>
                                        </div>
                                    }
                                    customClassName={'rounded'}
                                />
                                <Button
                                    placeholder={<AiOutlineClose />}
                                    typeOfButton={'text'}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => setOpenDrawer(false)}
                                />
                            </div>
                        </div>

                        {status === 'available' &&
                            (tasksDetails?.total_partitions_reduction > 0 ||
                                tasksDetails?.total_write_reduction_bytes > 0 ||
                                tasksDetails?.total_storage_reduction_bytes > 0 ||
                                tasksDetails?.total_read_reduction_bytes > 0) && (
                                <div className="saving-section">
                                    <p className="savings">
                                        {initialTasks?.length > 0 && initialTasks[0]?.type === 'remove_inactive_topic'
                                            ? 'You are going to save:'
                                            : 'You could have saved so far:'}
                                    </p>
                                    <div className="badges">
                                        {tasksDetails?.total_partitions_reduction > 0 && (
                                            <Badge type="cost" text={`${tasksDetails?.total_partitions_reduction?.toLocaleString()} Partitions`} />
                                        )}
                                        {tasksDetails?.total_storage_reduction_bytes > 0 && (
                                            <Badge type="cost" text={`${convertStorage(tasksDetails?.total_storage_reduction_bytes)} of Storage`} />
                                        )}
                                        {tasksDetails?.total_read_reduction_bytes > 0 && (
                                            <Badge type="cost" text={`${convertStorage(tasksDetails?.total_read_reduction_bytes)} of Reading`} />
                                        )}
                                        {tasksDetails?.total_write_reduction_bytes > 0 && (
                                            <Badge type="cost" text={`${convertStorage(tasksDetails?.total_write_reduction_bytes)} of Writing`} />
                                        )}
                                    </div>
                                </div>
                            )}

                        <div className="content">
                            {tasksDetails?.description}

                            <br />
                            {tasks?.the_fix && (
                                <>
                                    <h3
                                        style={{
                                            marginBottom: 6,
                                            color: 'var(--text-color)',
                                            fontWeight: 500
                                        }}
                                    >
                                        Fixes
                                    </h3>
                                    {tasksDetails?.the_fix}
                                </>
                            )}
                            {tasksDetails?.tha_fix && (
                                <>
                                    <h3
                                        style={{
                                            marginBottom: 6,
                                            color: 'var(--text-color)',
                                            fontWeight: 500
                                        }}
                                    >
                                        How Superstream will fix the issue
                                    </h3>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>{tasksDetails?.tha_fix}</div>
                                </>
                            )}
                            {tasksDetails?.risk && (
                                <>
                                    <h3
                                        style={{
                                            marginBottom: 6,
                                            color: 'var(--text-color)',
                                            fontWeight: 500
                                        }}
                                    >
                                        Risk management
                                    </h3>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>{tasksDetails?.risk}</div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="todo-list-drawer-items-top">
                        <div className="left">
                            <div className="tabs">
                                <div
                                    className={'tab ' + (activeTaskTab === 'fixable' ? 'active ' : '') + (countOfTasks?.fixable === 0 ? 'inactive' : '')}
                                    onClick={() => (countOfTasks?.fixable === 0 ? false : fetchByGroupTasks(selectedItem, 'fixable'))}
                                >
                                    Superstream can fix
                                    <span>{countOfTasks?.fixable}</span>
                                </div>
                                <div
                                    className={'tab ' + (activeTaskTab === 'not_fixable' ? 'active ' : '') + (countOfTasks?.notFixable === 0 ? 'inactive' : '')}
                                    onClick={() => (countOfTasks?.notFixable === 0 ? false : fetchByGroupTasks(selectedItem, 'not_fixable'))}
                                >
                                    Manual only
                                    <span>{countOfTasks?.notFixable}</span>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div style={{ border: '1px solid var(--button-border-color)', borderRadius: '32px' }}>
                                <SearchComponentStateless
                                    style={{ width: 200, flexDirection: 'row-reverse', justifyContent: 'space-between' }}
                                    onChange={handleTasksSearch}
                                    value={taskSearchValue}
                                    setSearchValue={setTaskSearchValue}
                                    placeholder="Start typing here..."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="todo-list-drawer-items" onScroll={handleScroll} style={{ maxHeight: `calc(100vh - ${topHeight}px)` }}>
                        <AnimatePresence initial={false}>
                            {tasks?.map((item, index) => (
                                <motion.div key={item.id} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} layout>
                                    <ListSubItem item={item} handleRemoveSubItem={handleSubtaskRemove} />
                                </motion.div>
                            ))}
                            {isUserUnderAssessment && (
                                <div className="todo-list-poc-wrapper">
                                    <ListSubItem item={tasks?.[0]} handleRemoveSubItem={handleSubtaskRemove} />
                                    <div className="todo-list-poc">
                                        <LimitedIcon style={{ width: 25, height: 25 }} />
                                        <div className="todo-list-poc-title">The rest of the view is currently locked</div>
                                    </div>
                                </div>
                            )}
                        </AnimatePresence>
                    </div>
                </Drawer>
            </div>
        );
    }
);

export default TodoList;
