import Button from 'components/button';
import { LuCopyMinus, LuCopyPlus } from "react-icons/lu";

const ToggleButton = ({ toggleAllRows, setToggleAllRows }) => {

    return (
        <Button
            typeOfButton={"circle"}
            size={"large"}
            placeholder={
                toggleAllRows ?
                    <LuCopyMinus size={17} />
                    :
                    <LuCopyPlus size={17} />
            }
            onClick={() => setToggleAllRows(!toggleAllRows)}
        />
    );
};

export default ToggleButton;

