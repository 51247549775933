import './style.scss';
import Drawer from 'components/drawer';
import { getIconByLang } from 'services/genericServices';
import { Divider, Spin } from 'antd';
import Button from 'components/button';
import { FaPlay } from 'react-icons/fa';
import Tooltip from 'components/tooltip/tooltip';
import { FixedSizeList as List } from 'react-window';
import { useEffect, useState } from 'react';
import Loader from 'components/loader';
import Lottie from 'lottie-react';
import animationData from 'assets/lotties/loading.json';

const OrphanClientsDrawer = ({ open, onClose, orphanClients, handleCompression, loading }) => {

    const renderClient = ({ index, style }) => {
        const client = orphanClients[index];
        return (
            <div key={index} className="orphans-client" style={style}>
                <div className="left">
                    <p className="title">Client #{client?.id}</p>
                    <div className="meta-wrapper">
                        <div className="meta">
                            <div className="meta-key">Language:</div>
                            <div className="meta-value">{getIconByLang(client?.language)}</div>
                        </div>
                        {client?.tags?.length > 0 && (
                            <>
                                <Divider type="vertical" />
                                <div className="meta">
                                    <div className="meta-key">Tags:</div>
                                    <div className="meta-value">{client?.tags?.join(', ')}</div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {client?.is_producer && (
                    <div className="right">
                        <Tooltip text={'Config'} placement={'top'}>
                            <Button shape="circle" placeholder={<FaPlay color={'var(--text-color)'} fontSize={10} />} onClick={() => handleCompression(client)} />
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Drawer open={open} onClose={onClose} styles={{ header: { display: 'none' } }}>
            <div className="orphan-clients-drawer">
                <div className="top">
                    <div className="title">
                        <h2>“Orphan” clients</h2>
                        {loading && <Lottie animationData={animationData} loop={true} />}
                    </div>
                    <p>Clients that are connected to a Kafka cluster that is not visible or connected to Superstream.</p>
                </div>
                <div className="middle">
                    {!loading && (
                        <List height={1000} itemCount={orphanClients.length} itemSize={100} width={'100%'}>
                            {renderClient}
                        </List>
                    )}
                </div>
            </div>
        </Drawer>
    );
};

export default OrphanClientsDrawer;
