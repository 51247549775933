import './style.scss';
import { useContext, useState } from 'react';
import Button from 'components/button';
import { Badge, Checkbox, Collapse } from 'antd';
import { IoIosArrowDown } from 'react-icons/io';
import Popover from 'components/popover';
import { TbFilterSearch } from "react-icons/tb";
import { Context } from "hooks/store";

const overlayStyles = {
    borderRadius: '12px',
    width: '279px',
    marginTop: '8px',
    padding: '0px'
};

const FilterButton = ({ data = {}, selectedFilters, setSelectedFilters, disabled, applyFilters, disableApplyButton = false, filterState = {}, resetFilters }) => {
    const [popoverShown, setPopoverShown] = useState(false);
    const [state] = useContext(Context);

    const panelStyle = {
        marginBottom: 24,
        background: 'transparent',
        border: 'none'
    };

    const handleSelectedFilters = (field, value) => {
        const currentFilters = selectedFilters[field] || [];

        if (currentFilters.includes(value)) {
            const newFilters = currentFilters.filter(item => item !== value);
            setSelectedFilters({
                ...selectedFilters,
                [field]: newFilters
            });
        } else {
            const newFilters = [...currentFilters, value];
            setSelectedFilters({
                ...selectedFilters,
                [field]: newFilters
            });
        }
    };

    const totalCount = Object.values(filterState).reduce((acc, curr) => acc + curr.length, 0);

    const progressStatuses = {
        completed: 'Completed',
        running: 'Running',
        failed: 'Failed',
        queued: 'Queued',
        cancelled: 'Cancelled',
    };

    const filterItems = [
        {
            key: '1',
            label: 'Progress statuses',
            children: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {Object.entries(progressStatuses).map(([key, value]) => {
                        return (
                            <div key={key} className="jobs-filter-popover-checkbox" style={{ display: 'flex', gap: 12 }}>
                                <Checkbox
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => handleSelectedFilters('progressStatuses', key)}
                                    checked={selectedFilters['progressStatuses']?.includes(key)}
                                />
                                {value}
                            </div>
                        );
                    })}
                </div>
            ),
            style: panelStyle,
            disabled: false,
        },
        {
            key: '2',
            label: 'Cluster name',
            children: (
                <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                    {state?.connections?.map((item, index) => {
                        return (
                            <div key={index} className="clients-filter-popover-checkbox"
                                 style={{display: 'flex', gap: 12}}>
                                <Checkbox
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => handleSelectedFilters('connections', item?.name)}
                                    checked={selectedFilters['connections']?.includes(item?.name)}
                                />
                                {item?.name}
                            </div>
                        );
                    })}
                </div>
            ),
            style: panelStyle,
            disabled: selectedFilters?.length === 0,
        },
    ];

    const contentPopover = (
        <div className="jobs-filter-popover">
            <div className="top">
                <p>Filter</p>
                <Button
                    onClick={() => {
                        resetFilters();
                        setPopoverShown(false);
                    }}
                    disabled={totalCount === 0}
                    typeOfButton={"text"}
                    placeholder={
                        <span style={{color: "var(--primary-color)"}}>Clear All</span>
                    }
                />
            </div>
            <div className="body">
                <Collapse
                    defaultActiveKey={['1', '2', '3', '4']}
                    expandIconPosition="end"
                    bordered={false}
                    style={{
                        background: 'transparent'
                    }}
                    items={filterItems.filter(item => !item.disabled)}
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <IoIosArrowDown color="var(--text-color)" style={{ rotate: '180deg', fontSize: 16 }} />
                        ) : (
                            <IoIosArrowDown color="var(--text-color)" style={{ rotate: '0deg', fontSize: 16 }} />
                        )
                    }
                />
            </div>
            <div className="bottom">
                {/*<Button
                    customClassName="modal-btn modal-btn-secondary"
                    placeholder={'Cancel'}
                    onClick={() => setPopoverShown(false)}
                    style={{padding: "5px 10px", fontSize: 13}}
                />*/}
                <Button
                    customClassName="modal-btn modal-btn-primary"
                    placeholder={'Apply'}
                    onClick={() => {
                        applyFilters();
                        setPopoverShown(false);
                    }}
                    disabled={disableApplyButton}
                    style={{padding: "5px 10px", fontSize: 13}}
                />
            </div>
        </div>
    );

    return (
        <div className="jobs-filter">
            <Popover
                overlayInnerStyle={overlayStyles}
                placement="bottom"
                content={contentPopover}
                trigger="click"
                onOpenChange={() => setPopoverShown(!popoverShown)}
                open={!disabled ? popoverShown : false}
            >
                <>
                    <Badge count={totalCount?.toString()} color="var(--primary-color)">
                        <Button
                            shape={"circle"}
                            typeOfButton={"text"}
                            size="large"
                            icon={<TbFilterSearch color="#475467" />}
                            style={{ background: 'var(--bg-white)', border: '1.2px solid #C8CEEB'}}
                            disabled={disabled}
                        />
                    </Badge>
                </>
            </Popover>
        </div>
    );
};

export default FilterButton;