import "./style.scss";
import Modal from "components/modal";
import Button from "components/button";
import { useCallback, useMemo, useState } from "react";
import Input from "components/input";
import { Form } from "antd";

const CustomRecreateTopicsModal = ({openModal, onSubmit, closeModal, maxAllowedInputValue}) => {
    const [inputValue, setInputValue] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = useCallback(() => {
        setLoadingState(true)

        onSubmit(inputValue).then(() => {
            setInputValue("")
            closeModal()
        }).finally(() => {
            setLoadingState(false)
        })
    }, [onSubmit, closeModal, inputValue]);

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every(field => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({errors}) => errors.length).length === 0);
    };

    return (
        <Modal
            isModalOpen={openModal}
            clickOutside={closeModal}
            className={"custom-confirm-modal"}
        >
            <Form
                name="forget-passowrd"
                onFinish={handleSubmit}
                autoComplete="off"
                form={form}
                onFieldsChange={onFieldsChange}
            >
                <div className="top">
                    <div className="title">Partition configuration</div>
                </div>
                <div className="middle">
                    <div className="content">
                        With how many partitions should this topic create with?
                    </div>
                    <Form.Item
                        name="partitions"
                        validateTrigger="onChange"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter partitions!',
                            },
                            {
                                validator: (_, value) => {
                                    const valueAsNumber = parseInt(value);
                                    if (valueAsNumber >= maxAllowedInputValue || valueAsNumber <= 0) {
                                        return Promise.reject(new Error(`Partition count for recreation cannot exceed the current number of partitions`));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                    <Input
                        type={'number'}
                        placeholder={'Enter partitions'}
                        subLabel={maxAllowedInputValue ? `The current partition count is ${maxAllowedInputValue}; the new value must be less than ${maxAllowedInputValue} and greater than 0.` : 'Amount of partitions'}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        value={inputValue}
                    />
                    </Form.Item>
                </div>
                <div className="bottom">
                    <Button customClassName="modal-btn modal-btn-secondary" placeholder="Cancel" onClick={closeModal}/>
                    <Button
                        htmlType={"submit"}
                        customClassName="modal-btn modal-btn-primary"
                        placeholder="Recreate"
                        loading={loadingState}
                        disabled={!isFormValid}
                    />
                </div>
            </Form>
        </Modal>
    );
};

export default CustomRecreateTopicsModal;