import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import Tooltip from "components/tooltip/tooltip";

export default function StatusChart({ data }) {
    const totalSum = data.reduce((acc, cur) => acc + cur.value, 0);

    const RoundedBarLeft = ({ x, y, width, height, fill }) => {
        const radius = 4;
        return (
            <path
                d={`M${x + radius},${y} h${width - radius} v${height} h-${width - radius} a${radius},${radius} 0 0 1 -${radius},-${radius} v-${height - 2 * radius} a${radius},${radius} 0 0 1 ${radius},-${radius} z`}
                fill={fill}
            />
        );
    };

    const RoundedBarRight = ({ x, y, width, height, fill }) => {
        const radius = 4;
        return (
            <path
                d={`M${x},${y} h${width - radius} a${radius},${radius} 0 0 1 ${radius},${radius} v${height - 2 * radius} a${radius},${radius} 0 0 1 -${radius},${radius} h-${width - radius} z`}
                fill={fill}
            />
        );
    };

    const RoundedBarSingle = ({ x, y, width, height, fill }) => {
        const radius = 4;
        const adjustedWidth = width * .96;
        const offset = (width - adjustedWidth) / 2;
        return (
            <path
                d={`M${x + offset},${y} h${adjustedWidth} a${radius},${radius} 0 0 1 ${radius},${radius} v${height - 2 * radius} a${radius},${radius} 0 0 1 -${radius},${radius} h-${adjustedWidth} a${radius},${radius} 0 0 1 -${radius},-${radius} v-${height - 2 * radius} a${radius},${radius} 0 0 1 ${radius},-${radius} z`}
                fill={fill}
            />
        );
    };

    const tooltipData = () => {
        if (data?.length === 0) return;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: 150,
                    position: 'relative',
                    zIndex: 99999,
                    padding: '2px 4px',
                }}
            >
                <p
                    style={{
                        color: 'var(--text-color)',
                        fontFamily: 'Inter',
                        fontSize: 14,
                        marginBottom: 4,
                    }}
                >
                    Total: {totalSum}
                </p>
                {data.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: '5px',
                        }}
                    >
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: 4,
                                backgroundColor: item.bg,
                                marginRight: '5px',
                            }}
                        />
                        <p style={{
                            margin: 0,
                            color: 'var(--text-color)',
                            fontFamily: 'Inter',
                            fontSize: 12,
                        }}>
                            {item.label}: {item.value}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Tooltip text={tooltipData()} className="status-tooltip" placement={"top"}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 3,
                    justifyContent: 'center'
                }}
            >
                {data?.length > 0 ?
                    <ResponsiveContainer width="100%" height={30}>
                        <BarChart data={[{name: 'Status', values: data}]} layout="vertical">
                            <XAxis type="number" hide domain={[0, totalSum]}/>
                            <YAxis type="category" dataKey="name" hide/>
                            {data.map((entry, index) => (
                                <Bar
                                    key={`bar-${index}`}
                                    dataKey={`values[${index}].value`}
                                    stackId="a"
                                    isAnimationActive={false}
                                    shape={
                                        data.length === 1 ? (
                                            <RoundedBarSingle {...entry} />
                                        ) : data.length > 1 && index === 0 ? (
                                            <RoundedBarLeft {...entry} />
                                        ) : index === data.length - 1 ? (
                                            <RoundedBarRight {...entry} />
                                        ) : null
                                    }
                                >
                                    <Cell
                                        fill={entry?.bg}
                                    />
                                </Bar>
                            ))}
                        </BarChart>
                    </ResponsiveContainer> :
                    <div style={{display: "flex", width: "94%", backgroundColor: "rgb(242, 244, 247)", borderRadius: 4, height: 16, marginRight: 3, marginLeft: 5}}></div>
                }
            <span style={{ fontSize: 12 }}>{totalSum}</span>
        </div>
        </Tooltip>
    );
}
